<template>
  <div>
    <router-link :to="`extras/manage?extraCategoryId=${selectedCategoryId}`" style="padding-bottom: 10px; display: block;">
      <v-btn type="button" color="primary">
        ADD EXTRA
      </v-btn>
    </router-link>

    <SearchBar ref="searchBar" label='Search for extra' @search="filterTable($event)"/>

    <v-data-table 
      :headers="headers" :items="extras.filter(e => selectedCategoryId == null || e.extraCategoryId == selectedCategoryId)" :search="search" 
      :loading="isLoading" :items-per-page="-1"
      item-key="id" class="elevation-1 mt-5">

      <template v-slot:[`item.actions`]="{ item }">
        <router-link :to="`extras/manage/${item.id}`">
          <v-icon small class="ml-5">mdi-pencil</v-icon>
        </router-link>
        <v-icon class="ml-5" small @click="remove(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { extraService } from '@/services';
  import SearchBar from '@/components/SearchBar.vue';
  
  export default {
    name: 'Extras',
    props: ['selectedCategoryId'],
    components: {
      SearchBar
    },
    data: () => ({
      isLoading: false,
      extras: [],
      search: "",
      headers: [
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
        { text: 'Actions' , align: 'end', value: 'actions' }
      ],
    }),
    created () {
      this.load();
    },
    methods: {
      load: async function() {
        this.isLoading = true;
        this.extras = await extraService.get();
        console.log(this.extras);
        this.isLoading = false;
      },
      filterTable: function(search) {
        this.search = search;
      },
      remove: async function(category) {
        this.$confirm(
        {
          message: `Are you sure you want to delete this extra?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              this.isLoading = true;
              var response = await extraService.remove(category.id);
              this.isLoading = false;
              if (response.status != 200) {
                alert("Error deleting");
                return;
              }

              this.load();
            }
          }
        })
      },
    }, 
    
  }
</script>
