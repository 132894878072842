<template>
  <div>
    <SearchBar ref="searchbar" label='Search for product' clearOnSearch="true" @search="search = $event; load();"/>

    <v-card class="product-form pa-3 mt-2" elevation="2" outlined> 
      <p><span>Name:</span> {{ product.name }}</p>
      <p><span>Desc:</span> {{ product.description }}</p>
      <p><span>Max Stock:</span> {{ product.maxStock }}</p>
      <p><span>Min Stock:</span> {{ product.minStock }}</p>
      <p><span>Current Stock:</span> {{ product.currentStock }}</p>

      <p><span>Qty:</span><v-text-field ref="stockField" filled v-model="qty"></v-text-field></p>

      <v-btn class="save-btn" type="button" color="success" v-on:click="save" v-if="product.name != ''">
        SAVE
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import { productService } from '@/services';

export default {
  name: 'Inventory',
  components: {
    SearchBar
  },
  data: () => ({
    isLoading: false,
    search: "",
    product: {
      name: "",
      description: "",
      maxStock: 0,
      minStock: 0,
      currentStock: 0
    },
    qty: 0,
  }),
  methods: {
    reset: function() {
      this.product = {
        name: "",
        description: "",
        maxStock: 0,
        minStock: 0,
        currentStock: 0
      };

      this.qty = 0;
    },
    load: async function() {
      this.isLoading = true;
      this.product = await productService.getByBarcode(this.search);
      this.isLoading = false;
      this.$refs.stockField.focus();
    },
    save: async function() {
      this.isLoading = true;
      this.product.currentStock = parseInt(this.product.currentStock) + parseInt(this.qty);
      await productService.update(this.product);
      this.product = null;
      this.isLoading = false;
      this.reset();
      this.$refs.searchbar.$children[0].$children[0].focus();
    }
  },
}
</script>

<style lang="scss" scoped>
  .product-form {
    max-width: 500px;
    span {
      display: inline-block;
      width: 100px;
      font-style: italic;
      font-size: 12px;
    }

    .save-btn {
      width: 100%;
    }
  }
</style>