<template>
  <div class="upload__block">
    <v-container v-if="!isLoading">
      <v-row>
        <v-col>
          <v-file-input show-size  @change="selectFile"></v-file-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn type="button" color="default" @click="close()">CLOSE</v-btn>
        </v-col>
        <v-col>
          <v-btn type="button" class="float-right" color="primary" @click="upload()">UPLOAD</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
import { toBase64 } from '@/helpers';
import { categoryService } from '@/services';
import { authHeader } from '@/helpers';

export default {
  props: ['url', 'ignoreStock'],
  data: () => ({ 
    selectedFile: "",
    isLoading: false
  }),
  methods: {
    close: function() {
      this.$emit('close');
    },

    selectFile: function(file) {
      this.selectedFile = file;
    },

    upload: async function() {
      this.isLoading = true;
      var base64 = await toBase64(this.selectedFile);
      var dto = {
        base64: base64,
        ignoreStock: this.ignoreStock
      }

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(dto)
      };

      var response = await fetch(`${process.env.VUE_APP_APIURL}${this.url}`, requestOptions)
      this.isLoading = false;
      if (response.status != 200) {
        alert("Error importing");
        return;
      }

      this.$emit('success');
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload__block {
    padding: 20px;
    background: #FFF;
  }
</style>

