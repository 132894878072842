<template>
  <div>
    <div class="d-flex mb-5">
      <v-spacer></v-spacer>

      <router-link to="/promotions/manage">
        <v-btn type="button" color="primary">
          ADD NEW PROMOTION
        </v-btn>
      </router-link>
    </div>

    <v-row>
      <v-data-table 
        :headers="headers" :items="data" 
        :loading="isLoading"
        item-key="refundedAt" class="elevation-1 mt-5 flex-grow-1">

        <template v-slot:[`item.startDate`]="{ item }">
          {{ item.startDate == null ? '' : moment(item.startDate).format("DD/MM/yyyy") }}
        </template>

        <template v-slot:[`item.endDate`]="{ item }">
          {{ item.endDate == null ? '' : moment(item.endDate).format("DD/MM/yyyy") }}
        </template>

        <template v-slot:[`item.enabled`]="{ item }">
          <v-checkbox readonly v-model="item.enabled" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <router-link class="logout-link"  :to="`/promotions/manage/${item.id}`">
            <v-icon small class="mr-2">mdi-pencil</v-icon>
          </router-link>
        </template> 

      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import { promotionService } from '@/services';
import moment from 'moment';

export default {
  name: 'Promotions',
  data: vm => ({
    isLoading: false,
    data: [],
    headers: [
      { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
      { text: 'Start Date', align: 'start', sortable: true, filterable: true, value: 'startDate' },
      { text: 'End Date', align: 'start', sortable: true, filterable: true, value: 'endDate' },
      { text: 'Type', align: 'start', sortable: true, filterable: true, value: 'promotionTypeFormatted' },
      { text: 'Enabled', align: 'start', sortable: true, filterable: true, value: 'enabled' },
      { text: 'Actions' , align: 'end', value: 'actions' }
    ],
  }),
  created () {
    this.load();
  },
  methods: {
    async load() {
      this.isLoading = true;
      this.data = await promotionService.get();
      

      this.isLoading = false;
    },
    getPromotionType(type) {
      if (type == 0)
        return 
    }

    // async remove(item) {
    //   this.isLoading = true;
    //   await discountRangeService.remove(item.id);
    //   this.isLoading = false;

    //   this.load();
    // }
  }
}
</script>
