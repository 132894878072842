import { authHeader } from '@/helpers';

export const userService = {
  login,
  logout,
  isAdmin,
  isStaff,
  resetPassword
};

async function resetPassword(dto) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(dto)
  };

  return fetch(`${process.env.VUE_APP_APIURL}/account/resetpassword`, requestOptions)
}

async function login(username, password) {
  var dto = {
    username: username,
    password: password
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dto)
  };


  return fetch(`${process.env.VUE_APP_APIURL}/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function isAdmin() {
  var user = JSON.parse(localStorage.getItem('user') || {});
  if (user == null || user.role != 'Admin' ) {
    return false;
  }
  
  return true;
}

function isStaff() {
  var user = JSON.parse(localStorage.getItem('user') || {});
  if (user == null || user.role != 'Staff' ) {
    return false;
  }
  
  return true;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 || response.status == 400) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}