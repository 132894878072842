<template>
  <div>
   <v-toolbar flat class="toolbar-container mb-2" height='100'>
      <v-row>
        <v-col>
          <div class="toolbar-datepicker">
            <DateRangePicker :initialDates="dates" @blur="updateDates"  />
          </div>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-btn block class="search-btn" type="button" color="success" v-on:click="load">
          SEARCH
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
       <v-data-table 
        :headers="headers" :items="data" 
        :loading="isLoading" :items-per-page="-1"
        item-key="deletedAt" class="elevation-1 mt-5 flex-grow-1">

        <template v-slot:[`item.deletedAt`]="{ item }">
          {{ moment(item.deletedAt).format("DD/MM/yyyy HH:mm:ss") }}
        </template>

        <template v-slot:[`item.deletedAuditType`]="{ item }">
          <span v-if="item.deletedAuditType == 0">Order</span>
          <span v-if="item.deletedAuditType == 1">Table</span>
          <span v-if="item.deletedAuditType == 2">Item</span>
        </template>
        
         <template v-slot:[`item.option`]="{ item }">
          <v-btn type="button" color="red" v-on:click="deleteAudit(item)">
            DELETE
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import { reportService } from '@/services';
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'Audit',
  components: {
    DateRangePicker,
  },
  data: vm => ({
    dates: [new Date().toISOString().substr(0, 10)],
    isLoading: false,
    data: [],
    headers: [
      { text: 'Deleted At', align: 'start', sortable: true, filterable: true, value: 'deletedAt' },
      { text: 'Staff', align: 'start', sortable: true, filterable: true, value: 'user' },
      { text: 'Reason', align: 'start', sortable: true, filterable: true, value: 'reason' },
      { text: 'Audit', align: 'start', sortable: true, filterable: true, value: 'deletedAuditType' },
      { text: 'Description', align: 'start', sortable: true, filterable: true, value: 'description' },
      { text: 'Option', align: 'start', sortable: false, filterable: false, value: 'option' },
    ],
  }),
  async created () {
    if (userService.isStaff()) {
      router.push('/products');
    }
    
    this.load();
  },
 methods: {
    updateDates(newDates) {
      this.dates = newDates;
    },
    async load() {
      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1)
        endDate = this.dates[1];

      this.isLoading = true;
      this.data = await reportService.getAuditReport(startDate, endDate);
      this.isLoading = false;
    },
    async deleteAudit(audit) {
      var response = confirm("Are you sure you want to delete this audit?");
      if (!response)
        return;

      var idx = this.data.indexOf(audit);
      await reportService.deleteAudit(audit.id);
      this.data.splice(idx, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar-container {
    background: #FFF !important;
    padding: 10px;
    height: auto;
    border-radius: 8px;

    border: 1px solid lighten(#7B5CD8, 30);
  }

  .toolbar-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toolbar-datepicker {
      width: 250px;
    }
  }

</style>
