<template>
  <v-row>
    <v-col>
      <v-form class="form" ref="form" @submit.prevent="handleSubmit">
        <label>This is currently used for our online ordering system</label><br/><br/>

        <label>Delivery Message For Notification</label>
        <v-text-field v-model="deliveryMessageForNotification" label="Delivery Message For Notification" />

        <v-file-input label="App Logo " truncate-length="15" show-size accept="image/*" prepend-icon="mdi-camera" v-model="appImage" @change="() => upload(appImage, 'appImageBase64')"></v-file-input>
        <img v-if="appImageBase64 != null" style='display:block; margin: 10px; max-width: 250px;' :src="appImageBase64" />

        <v-file-input label="Background Image" truncate-length="15" show-size accept="image/*" prepend-icon="mdi-camera" v-model="appImageBackground" @change="() => upload(appImageBackground, 'appImageBackgroundBase64')"></v-file-input>
        <img v-if="appImageBackgroundBase64 != null" style='display:block; margin: 10px; max-width: 250px;' :src="appImageBackgroundBase64" />

        <v-checkbox v-model="automaticOnlineStockControl" label="Automatic Online Stock Control"></v-checkbox>

        <v-btn type="submit" color="primary" class="mr-4">
          SUBMIT
        </v-btn> 
      </v-form>
      
    </v-col>
  </v-row>
</template>

<script>

import Compressor from 'compressorjs';
import { appService } from '@/services';
export default {
  name: 'Settings',
  components: {
    
  },
  data: () => ({
    appImage: null,
    appImageBackground: null,

    appImageBase64: null,
    appImageBackgroundBase64: null,

    deliveryMessageForNotification: null,

    automaticOnlineStockControl: false,

    app: null
  }),
  async created () {
    this.app = await appService.get();
    this.appImageBase64 = this.app.appSettings.appImageBase64;
    this.appImageBackgroundBase64 = this.app.appSettings.appImageBackgroundBase64;
    this.deliveryMessageForNotification = this.app.appSettings.deliveryMessageForNotification;
    this.automaticOnlineStockControl = this.app.appSettings.automaticOnlineStockControl;
  },
  methods: {
    upload: function(file, field) {
      if (!file) {
        return;
      }

      new Compressor(file, {
        quality: 0.6,
        maxWidth: 512,

        success: (result) => {
          let reader = new FileReader();
          reader.onload = (event) => {
            this.$set(this, field, reader.result);
          }
          reader.readAsDataURL(result);
          
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
    async handleSubmit() {
      this.app.appSettings.appImageBase64 = this.appImageBase64;
      this.app.appSettings.appImageBackgroundBase64 = this.appImageBackgroundBase64;
      this.app.appSettings.deliveryMessageForNotification = this.deliveryMessageForNotification;
      this.app.appSettings.automaticOnlineStockControl = this.automaticOnlineStockControl;

      var response = await appService.update(this.app);
      if (response.status != 200) {
        alert("Error saving");
        return;
      }

      alert("Updated");
    }
  },
}
</script>

<style lang="scss" scoped>
  
</style>