<template>
  <div>
    <div class="d-flex mb-5">
      <v-btn type="button" color="primary" v-on:click="exportExcel()">
        EXPORT
      </v-btn>
    </div>

    <v-row>
      <v-data-table 
        :headers="headers" :items="data" 
        :loading="isLoading" :items-per-page="-1"
        show-expand :expanded.sync="expanded" :single-expand="true"
        item-key="category" class="elevation-1 mt-5 flex-grow-1">

       <template v-slot:header.total="{ header }">
        {{ header.text }} 
        <span v-if="data.length > 0">
          (£{{ Math.round(data.map(d => d.total).reduce((sum, x) => sum + x) * 100) / 100 }})
        </span>
      </template>

        <template v-slot:[`item.total`]="{ item }">
          £{{ item.total.toFixed(2) }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
         
          <td :colspan="headers.length">
            <table class="sub-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Stock</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tr v-for="product in item.products" v-bind:key="product.id">
                <td>{{ product.name }}</td>
                <td>{{ product.currentStockFormatted }}</td>
                <td>£{{ product.total.toFixed(2) }}</td>
              </tr>
            </table>
          </td>
      
        </template>

      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import { reportService } from '@/services';
import { b64toBlob } from '@/helpers'
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'CurrentStock',
  data: vm => ({
    isLoading: false,
    data: [],
    expanded: [],
    headers: [
      { text: 'Category', align: 'start', sortable: true, filterable: true, value: 'category' },
      { text: 'Stock #', align: 'start', sortable: true, filterable: true, value: 'totalStock' },
      { text: 'Total', align: 'start', sortable: true, filterable: true, value: 'total' },
    ],
  }),
  created () {
    if (userService.isStaff()) {
      router.push('/products');
    }
    
    this.load();
  },
  methods: {
    async load() {
      this.isLoading = true;
      this.data = await reportService.getCurrentStockReport();
      console.log(this.data);
      this.isLoading = false;
    },

    exportExcel: async function() {
      this.isLoading = true;
      var response = await reportService.exportCurrentStockReport();
      if (response.status != 200) {
        alert("Error exporting");
        return;
      }

      var base64 = await response.json();
      const blob = b64toBlob(base64, 'application/vnd.ms-excel');
      saveAs(blob, 'Stock.xls');

      this.isLoading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .sub-table {
    width: 50%;
    
    th {
      text-align: left;
      padding-top: 5px;
    }

    tr {
      td {
        border-bottom: 1px solid #CECECE;
      }
    }
  }
</style>
