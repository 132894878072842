<template>
  <div>
    <v-toolbar flat class="toolbar-container mb-2" height='100'>
      <v-row>
        <v-col>
          <div class="toolbar-datepicker">
            <DateRangePicker :initialDates="dates" @blur="updateDates" />
          </div>
        </v-col>

        <v-col>
          <v-autocomplete
            label="Product"
            :items="products"
            item-text="name"
            item-value="id"
            v-model="selectedProduct"
            clearable
            :loading="productsLoading"
            :search-input.sync="search"
            return-object
            hide-details
            cache-items
            :filter="customFilter"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.category">
                  {{ item.category.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row>
      <v-col>
        <v-btn block class="search-btn" type="button" color="success" @click="load">
          SEARCH
        </v-btn>
      </v-col>
    </v-row>

    <!-- Summary Cards -->
    <v-row v-if="data.length">
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-text>
            <div class="text-subtitle-2">Total Staff</div>
            <div class="text-h5">{{ data.length }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-text>
            <div class="text-subtitle-2">Total Quantity</div>
            <div class="text-h5">
              {{ data.reduce((sum, staff) => sum + staff.totalQuantitySold, 0) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-text>
            <div class="text-subtitle-2">Total Revenue</div>
            <div class="text-h5">
              £{{ data.reduce((sum, staff) => sum + staff.totalRevenue, 0).toFixed(2) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="isLoading"
          :items-per-page="-1"
          :single-expand="true"
          show-expand
          item-key="staffName"
          class="elevation-1 mt-5"
        >
          <template v-slot:[`item.totalRevenue`]="{ item }">
            £{{ item.totalRevenue.toFixed(2) }}
          </template>

          <template v-slot:[`item.revenuePercentage`]="{ item }">
            {{ item.revenuePercentage.toFixed(1) }}%
          </template>

          <template v-slot:[`item.averageDiscount`]="{ item }">
            £{{ item.averageDiscount.toFixed(2) }}
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length">
              <div class="sales-detail pa-4">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Order Ref</th>
                        <th class="text-right">Quantity</th>
                        <th class="text-right">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="sale in item.sales" :key="sale.orderRef">
                        <td>{{ formatDate(sale.saleDate) }}</td>
                        <td>{{ sale.orderRef }}</td>
                        <td class="text-right">{{ sale.quantity }}</td>
                        <td class="text-right">£{{ sale.price.toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import { reportService, productService } from '@/services';
import moment from 'moment';

export default {
  name: 'StaffProductSales',
  components: {
    DateRangePicker,
  },
  data: () => ({
    dates: [new Date().toISOString().substr(0, 10)],
    isLoading: false,
    products: [],
    selectedProduct: null,
    search: null,
    productsLoading: false,
    data: [],
    headers: [
      { text: 'Staff Member', align: 'start', sortable: true, value: 'staffName' },
      { text: 'Quantity Sold', align: 'right', sortable: true, value: 'totalQuantitySold' },
      { text: 'Revenue', align: 'right', sortable: true, value: 'totalRevenue' },
    ],
  }),
  async created() {
    await this.getProducts();
  },
  methods: {
    updateDates(newDates) {
      this.dates = newDates;
    },
    async getProducts() {
      try {
        this.products = await productService.get();
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    customFilter(item, queryText) {
      const productName = item.name.toLowerCase();
      const categoryName = item.category?.name?.toLowerCase() || '';
      const searchText = queryText.toLowerCase();
      
      return productName.indexOf(searchText) > -1 || 
            categoryName.indexOf(searchText) > -1;
    },
    async load() {
      if (!this.selectedProduct?.id) return;

      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1) {
        endDate = this.dates[1];
      }

      this.isLoading = true;
      try {
        this.data = await reportService.getStaffProductSalesReport(
          startDate,
          endDate,
          this.selectedProduct.id  
        );
      } catch (error) {
        console.error('Error loading report:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async exportReport() {
      if (!this.selectedProduct) return;

      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1) {
        endDate = this.dates[1];
      }

      try {
        const response = await reportService.exportStaffProductSalesReport(
          startDate,
          endDate,
          this.selectedProduct
        );
        
        const byteCharacters = atob(response);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Staff_Product_Sales_${moment().format('YYYY-MM-DD')}.xlsx`;
        link.click();
      } catch (error) {
        console.error('Error exporting report:', error);
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar-container {
  background: #FFF !important;
  padding: 10px;
  height: auto;
  border-radius: 8px;
  border: 1px solid lighten(#7B5CD8, 30);
}

.toolbar-content {
  display: flex;
  flex-direction: row;
  width: 100%;

  .toolbar-datepicker {
    width: 250px;
  }
}

.sales-detail {
  background-color: #f5f5f5;
}
</style>