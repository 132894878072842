<template>
  <div>
    <div class="d-flex mb-5">
      <v-spacer></v-spacer>

      <router-link to="suppliers/manage">
        <v-btn type="button" color="primary">
          ADD NEW SUPPLIER
        </v-btn>
      </router-link>
    </div>

    <SearchBar label='Search for supplier' @search="search = $event"/>

    <v-data-table 
      :headers="headers" :items="suppliers" :search="search" 
      :loading="isLoading"
      item-key="name" class="elevation-1 mt-5">

      <template v-slot:[`item.actions`]="{ item }">
        <router-link :to="`/suppliers/manage/${item.id}`">
          <v-icon small class="mr-2">mdi-pencil</v-icon>
        </router-link>
        <v-icon small @click="remove(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import SearchBar from '@/components/SearchBar.vue';
  import Upload from '@/components/Upload.vue';

  import { supplierService } from '@/services';

  export default {
    name: 'Suppliers',
    components: {
      SearchBar,
    },
    data: () => ({
      isLoading: false,
      suppliers: [],
      search: "",
      headers: [
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
        { text: 'Actions' , align: 'end', value: 'actions' }
      ],
    }),
    created () {
      this.load();
    },
    methods: {
      load: async function() {
        this.isLoading = true;
        this.suppliers = await supplierService.get();
        this.isLoading = false;
      },
      remove: async function(supplier) {
        this.isLoading = true;
        var response = await supplierService.remove(supplier.id);
        this.isLoading = false;
        if (response.status != 200) {
          alert("Error deleting");
          return;
        }

        this.load();
      },
    }
  }
</script>
