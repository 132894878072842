<template>
  <div>
    <h2><v-icon v-on:click="goBack" color="primary" medium>mdi-arrow-left</v-icon><span>Add Extra</span></h2>
    <v-form class="form" ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
      <v-checkbox v-model="fromProduct" label="From Product"></v-checkbox>

      <v-select v-if="fromProduct" :items="products" item-text="name" v-model="productId" item-value="id" label="Product" :rules="[v => !!v || 'Product is required']" />

      <v-text-field v-model="name" label="Name" :rules="[v => !!v || 'Name is required']" />

      <v-text-field v-model="description" label="Description" />

      <v-text-field v-model="price" label="Price" :rules="[v => !!v || 'Name is required']"/>

      <v-select :items="extraCategories" item-text="name" v-model="extraCategoryId" item-value="id" label="Extra Category" :rules="[v => !!v || 'Name is required']" />

      <v-checkbox v-model="eposOnly" label="EPOS Only"></v-checkbox>

      <label v-if="showSuccess" style="font-weight: bold; margin-bottom: 15px; color: green">Successfully saved</label>
      <div>
        <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
          SUBMIT
        </v-btn> 

        <v-btn type="button" :disabled="!valid" color="primary" class="mr-4" v-on:click="saveAnother()">
          SUBMIT & ADD ANOTHER
        </v-btn> 
      </div>

    </v-form>
  </div>
</template>

<script>

import { extraService, extraCategoryService, productService } from '@/services';

export default {
  data () {
    return {
      fromProduct: false, 

      id: null,
      name: '',
      description: "",
      price: null,
      extraCategoryId: null,
      eposOnly: false,
      productId: null,

      extraCategories: [],

      submitted: false,
      valid: false,
      showSuccess: false,
    }
  },
  async created () {
    await this.loadExtraCategories();
    await this.loadProducts();

    if (this.$route.query.extraCategoryId != null) {
      this.extraCategoryId = this.$route.query.extraCategoryId;
    }
    var id = this.$route.params.id;
    if (id != null) {
      var dbExtra = await extraService.getById(id);

      this.id = dbExtra.id;
      this.name = dbExtra.name;
      this.price = dbExtra.price;
      this.extraCategoryId = dbExtra.extraCategoryId;
      this.eposOnly = dbExtra.eposOnly;
    }
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    loadExtraCategories: async function() {
      this.extraCategories = await extraCategoryService.get();
    },
    loadProducts: async function() {
      this.products = await productService.get();
    },
    async saveAnother () {
      this.submit(false);
    },
    async handleSubmit (e) {
      this.submit(true);
    },
    async submit(reset = true) {
      this.showSuccess = false;
      if (!this.$refs.form.validate())
        return;

      this.submitted = true;

      var dto = {
        id: this.id,
        name: this.name,
        price: this.price,
        extraCategoryId: this.extraCategoryId,
        eposOnly: this.eposOnly,
        productId: this.productId,
      }

      var response = null;
      if (dto.id != null)
        response = await extraService.update(dto);
      else
        response = await extraService.add(dto);

      if (response.status != 200) {
        alert("Error saving");
        return;
      }

      if (!reset) {
        this.id = null;
        this.name = null;
        this.description = null;
        this.price = null;
        this.eposOnly = false;
        this.showSuccess = true;
        return;
      }
      this.$router.go(-1);
    }
  },
  watch: {
    productId: function(newVal, oldVal) {
      const selectedProduct = this.products.find(p => p.id == newVal);
      if (selectedProduct == null) return;
      
      this.name = selectedProduct.name;
      this.description = selectedProduct.description;
    },
    fromProduct: function(newVal, oldVal) {
      this.productId = null;
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }
</style>

