import { authHeader } from '@/helpers';

export const supplierService = {
  get,
  getById,
  add,
  update,
  remove,
};

async function get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/supplier/get`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/supplier/getbyid?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function add(category) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(category)
  };

  return fetch(`${process.env.VUE_APP_APIURL}/supplier/post`, requestOptions)
}

async function update(category) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(category)
  };

  return fetch(`${process.env.VUE_APP_APIURL}/supplier/put`, requestOptions)
}

async function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_APIURL}/supplier/delete?id=${id}`, requestOptions)
}
