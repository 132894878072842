<template>
  <div>
    <v-toolbar flat class="toolbar-container mb-2" height='100'>
      <v-row >
        <v-col>
          <div class="toolbar-datepicker">
            <DateRangePicker :initialDates="dates" @blur="updateDates"  />
          </div>
        </v-col>

        <v-col v-if="stores.length > 1">
          <v-select :items="stores" item-text="name" v-model="selectedStoreId" item-value="id" />
        </v-col>
      </v-row>
    </v-toolbar>

     <v-row>
      <v-col>
        <v-btn block class="export-btn" type="button" color="primary" v-on:click="exportReport">
          EXPORT
        </v-btn>
      </v-col>

      <v-col>
        <v-btn block class="search-btn" type="button" color="success" v-on:click="load">
          SEARCH
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div>
          <TakingsChart :data="data"/>
        </div>
      </v-col>
    </v-row>

    <v-row>
       <v-data-table 
        :headers="computedHeaders" :items="data" 
        :loading="isLoading"
        dense
        item-key="takingsDate" class="elevation-1 mt-5 flex-grow-1">

        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th :colspan="headers.length" v-if="data != null && data.length > 0">
                Total: £{{ data.map(d => d.total).reduce((x, y) => x + y).toFixed(2) }} | 
                Cash: £{{ data.map(d => d.cash).reduce((x, y) => x + y).toFixed(2) }} | 
                Card: £{{ data.map(d => d.card).reduce((x, y) => x + y).toFixed(2) }} | 
                Net Profit: £{{ data.map(d => d.netProfit).reduce((x, y) => x + y).toFixed(2) }}
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:[`item.takingsDate`]="{ item }">
          {{ moment(item.takingsDate).format("DD/MM/yyyy") }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          £{{ item.total.toFixed(2) }}
        </template>

        <template v-slot:[`item.totalDiscount`]="{ item }">
          £{{ item.totalDiscount.toFixed(2) }}
        </template>

         <template v-slot:[`item.cash`]="{ item }">
          £{{ item.cash.toFixed(2) }}
        </template>

         <template v-slot:[`item.card`]="{ item }">
          £{{ item.card.toFixed(2) }}
        </template>

        <template v-slot:[`item.vat`]="{ item }">
          £{{ item.vat.toFixed(2) }}
        </template>

        <template v-slot:[`item.netProfit`]="{ item }">
          £{{ item.netProfit.toFixed(2) }}
        </template>

        <template v-slot:[`item.totalExVAT`]="{ item }">
          £{{ item.totalExVAT.toFixed(2) }}
        </template>

        <template v-slot:[`item.totalBuyingPrice`]="{ item }">
          £{{ item.totalBuyingPrice.toFixed(2) }}
        </template>

        <template v-slot:[`item.serviceChargeCash`]="{ item }">
          £{{ item.serviceChargeCash.toFixed(2) }}
        </template>

        <template v-slot:[`item.serviceChargeCard`]="{ item }">
          £{{ item.serviceChargeCard.toFixed(2) }}
        </template>

        <template v-slot:[`item.serviceChargeTotal`]="{ item }">
          £{{ item.serviceChargeTotal.toFixed(2) }}
        </template>
      </v-data-table>
    </v-row>
   
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import TakingsChart from '@/components/charts/TakingsChart.vue';
import { reportService } from '@/services';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { b64toBlob } from '@/helpers';
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'Takings',
  components: {
    DateRangePicker,
    TakingsChart
  },
  data: vm => ({
    // dates: [new Date().toISOString().substr(0, 10)],
    stores: [],
    selectedStoreId: null,

    dates: [moment().add(-7, 'd').toISOString().substr(0, 10), moment().toISOString().substr(0, 10)],
    isLoading: false,

    data: [],
    headers: [
      { text: 'Date', align: 'start', sortable: true, filterable: true, value: 'takingsDate' },
      { text: '# Sales', align: 'start', sortable: true, filterable: true, value: 'noOfSales' },
      { text: '# Items', align: 'start', sortable: true, filterable: true, value: 'noOfItems' },
      { text: 'Cash', align: 'start', sortable: true, filterable: true, value: 'cash' },
      { text: 'Card', align: 'start', sortable: true, filterable: true, value: 'card' },
      { text: 'Discount', align: 'start', sortable: true, filterable: true, value: 'totalDiscount' },
      { text: 'Total (Ex VAT)', align: 'start', sortable: true, filterable: true, value: 'totalExVAT' },
      // { text: 'Cost of Goods (Ex VAT)', align: 'start', sortable: true, filterable: true, value: 'totalBuyingPrice' },
      { text: 'VAT', align: 'start', sortable: true, filterable: true, value: 'vat' },
      { text: 'Net Profit', align: 'start', sortable: true, filterable: true, value: 'netProfit' },
      { text: 'Total', align: 'start', sortable: true, filterable: true, value: 'total' },
      { text: 'Service Charge Cash', align: 'start', sortable: true, filterable: false, value: 'serviceChargeCash' },
      { text: 'Service Charge Card', align: 'start', sortable: true, filterable: false, value: 'serviceChargeCard' },
      { text: 'Service Charge Total', align: 'start', sortable: true, filterable: false, value: 'serviceChargeTotal' },
    ],
  }),
  async created () {
    if (userService.isStaff()) {
      router.push('/products');
    }
    // Load our stores
    this.stores = await reportService.getStores();
    this.stores.unshift({
      id: null,
      name: 'All'
    });

    this.load();
  },
  methods: {
    updateDates(newDates) {
      this.dates = newDates;
    },
    async load() {
      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1)
        endDate = this.dates[1];

      this.isLoading = true;
      this.data = await reportService.getTakingsReport(startDate, endDate, this.selectedStoreId);
      console.log(this.data);

      this.isLoading = false;
    },
    async exportReport() {
      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1)
        endDate = this.dates[1];

      this.isLoading = true;
      var base64 = await reportService.exportTakingsReport(startDate, endDate, this.selectedStoreId);
      if (base64 == null) {
        this.isLoading = false;
        alert("Error exporting");
        return;
      }

      const blob = b64toBlob(base64, 'application/vnd.ms-excel');
      saveAs(blob, 'Takings Report.xlsx');

      this.isLoading = false;
    }
  },
  computed: {
    computedHeaders() {
      if (this.data.filter(d => d.serviceChargeTotal > 0).length > 0) {
        return this.headers;
      } else {
        return this.headers.filter(h => h.filterable == true);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar-container {
    background: #FFF !important;
    padding: 10px;
    height: auto;
    border-radius: 8px;

    border: 1px solid lighten(#7B5CD8, 30);
  }

  .toolbar-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toolbar-datepicker {
      width: 250px;
    }
  }

  .sub-total {
    ul {
      width: 100%;
      list-style-type: none;
      margin: 0;
      padding: 10px 10px;
    }
  }

  .export-btn {
    margin-right: 10px;
  }
</style>
