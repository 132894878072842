<template>
  <div>
    <v-dialog v-model="showUpload" width="500">
      <template>
        <Upload url="/category/import" @close="showUpload = false;" @success="showUpload = false; load()"/>
      </template>
    </v-dialog>

    <v-row class="mb-1">
      <v-col>
        <v-btn block type="button" color="primary" v-on:click="exportExcel()">
          EXPORT
        </v-btn>
      </v-col>

      <v-col>
        <v-btn block type="button" color="primary" v-on:click="showUpload = true">
          IMPORT
        </v-btn>
      </v-col>

      <v-col>
        <router-link to="categories/manage">
          <v-btn block type="button" color="primary">
            ADD NEW CATEGORY
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <!-- <div class="d-flex mb-5">
      <v-btn type="button" color="primary" v-on:click="exportExcel()">
        EXPORT
      </v-btn>

      <v-btn type="button" color="primary" style="margin-left: 10px" v-on:click="showUpload = true">
        IMPORT
      </v-btn>

      <v-spacer></v-spacer>

      <router-link to="categories/manage">
        <v-btn type="button" color="primary">
          ADD NEW CATEGORY
        </v-btn>
      </router-link>
    </div> -->

    <div class="filters">
      <SearchBar label='Search...' @search="search = $event"/>

      <div class="filter">
        <v-select 
          :items="sortedCategories" 
          item-text="name" 
          item-value="id"
          v-model="filteredCategory" 
          return-object 
          label="Category" 
          @change="load()" 
          hide-details
          density="compact"/>
      </div>

      <div class="filter">
        <v-checkbox 
          v-model="topLevelCategoriesOnly" 
          @change="load()"
          label="Top Level Categories Only" 
          class="ma-0 pa-0" 
          hide-details>
        </v-checkbox>
      </div>
    </div>

    <v-data-table 
      :headers="headers" :items="filteredCategories" :search="search" 
      :loading="isLoading"
      item-key="name" class="elevation-1 mt-5">

      <template v-slot:body="props">
        <draggable :list="props.items" tag="tbody" :disabled="!draggableEnabled" :move="onMoveCallback" :clone="onCloneCallback" @end="onDropCallback">
          <data-table-row-handler
            v-for="(item, index) in props.items"
            :key="index"
            :item="item"
            :headers="headers">

            <template v-slot:[`item.sort`]="{ item }">
              <v-icon v-if="draggableEnabled" style="cursor: pointer">mdi-drag</v-icon>
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <img v-if="item.imageUrlThumbnail != null" :src="imageUrl + item.imageUrlThumbnail" />
            </template>

            <template v-slot:[`item.ageCheck`]="{ item }">
              <div v-if="item.ageCheck">YES</div>
              <div v-if="!item.ageCheck">NO</div>
            </template>

            <template v-slot:[`item.isExcludedFromDiscount`]="{ item }">
              <div v-if="item.isExcludedFromDiscount">YES</div>
              <div v-if="!item.isExcludedFromDiscount">NO</div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <router-link :to="`/categories/manage/${item.id}`">
                <v-icon small class="mr-2">mdi-pencil</v-icon>
              </router-link>
              <v-icon small @click="remove(item)">mdi-delete</v-icon>
            </template>

          </data-table-row-handler>
        </draggable>
      </template>


      <!-- <template v-slot:[`item.image`]="{ item }">
        <img v-if="item.imageUrlThumbnail != null" :src="imageUrl + item.imageUrlThumbnail" />
      </template>

      <template v-slot:[`item.ageCheck`]="{ item }">
        <div v-if="item.ageCheck">YES</div>
        <div v-if="!item.ageCheck">NO</div>
      </template>

      <template v-slot:[`item.isExcludedFromDiscount`]="{ item }">
        <div v-if="item.isExcludedFromDiscount">YES</div>
        <div v-if="!item.isExcludedFromDiscount">NO</div>
      </template>

       <template v-slot:[`item.actions`]="{ item }">
        <router-link :to="`/categories/manage/${item.id}`">
          <v-icon small class="mr-2">mdi-pencil</v-icon>
        </router-link>
        <v-icon small @click="remove(item)">mdi-delete</v-icon>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
  import SearchBar from '@/components/SearchBar.vue';
  import Upload from '@/components/Upload.vue';
   import DataTableRowHandler from '@/components/table/DataTableRowHandler';
  import draggable from "vuedraggable";

  import { categoryService } from '@/services';
  import { saveAs } from 'file-saver';
  import { b64toBlob } from '@/helpers'

  export default {
    name: 'Categories',
    components: {
      SearchBar,
      Upload,
      DataTableRowHandler,
      draggable,
    },
    data: () => ({
      imageUrl: process.env.VUE_APP_APIURL.substring(0, process.env.VUE_APP_APIURL.length - 3),
      isLoading: false,
      sortedCategories: [],
      categories: [],
      filteredCategories: [],
      search: "",
      filteredCategory: null,
      topLevelCategoriesOnly: false,
      headers: [
        { text: '', align: 'start', sortable: false, filterable: false, value: 'sort', width: 40 },
        { text: '', align: 'start', sortable: false, filterable: false, value: 'image', width: 80 },
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
        { text: 'Description', align: 'start', sortable: true, filterable: true, value: 'description' },
        { text: 'Parent Category', align: 'start', sortable: true, filterable: true, value: 'parentCategory.name' },
        { text: 'Popup Notes', align: 'start', sortable: true, filterable: true, value: 'popupNotes' },
        { text: 'Age Check', align: 'start', sortable: true, filterable: true, value: 'ageCheck' },
        { text: 'Is Excluded From Discount', align: 'start', sortable: true, filterable: true, value: 'isExcludedFromDiscount' },
        { text: 'Actions' , align: 'end', value: 'actions' }
      ],

      showUpload: false,

      movedElement: null,
    }),
    created () {
      this.load();
    },
    methods: {
      load: async function() {
        this.isLoading = true;
        this.categories = await categoryService.get();

        // Create our filter drop down
        var sortedCategories = [];
        for (let category of [...this.categories].sort((a, b) => a.name.localeCompare(b.name))) {
          if (category.parentCategoryId == null ) {
            sortedCategories.push({ header: category.name, divider: true });
          }
          sortedCategories.push(category);
        }
        
        sortedCategories.unshift({ 
          id: null,
          name: "All"
        });

        this.sortedCategories = sortedCategories;

        this.filteredCategories = this.categories;
        if (this.topLevelCategoriesOnly) { 
          this.filteredCategories = this.categories.filter(c => c.parentCategoryId == null);
        }
        if (this.filteredCategory != null && this.filteredCategory.id != null) {
          this.filteredCategories = this.filteredCategories.filter(c => c.parentCategoryId == this.filteredCategory.id);
        }
        
        this.isLoading = false;
      },
      remove: async function(category) {
        this.isLoading = true;
        var response = await categoryService.remove(category.id);
        this.isLoading = false;
        if (response.status != 200) {
          alert("Error deleting");
          return;
        }

        this.load();
      },
      exportExcel: async function() {
        this.isLoading = true;
        var response = await categoryService.exportExcel();
        if (response.status != 200) {
          alert("Error exporting");
          return;
        }

        var base64 = await response.json();
        const blob = b64toBlob(base64, 'application/vnd.ms-excel');
        saveAs(blob, 'Categories.xls');

        this.isLoading = false;
      },
      onCloneCallback(item) {
        // Create a fresh copy of item
        const cloneMe = JSON.parse(JSON.stringify(item));
        return cloneMe;
      },
      onMoveCallback(evt, originalEvent) {
        const item = evt.draggedContext.element;
        this.movedElement = item;
        return true;
      },
      onDropCallback(evt, originalEvent) {
        const newPosition = evt.newIndex + 1;
        this.movedElement.position = newPosition;
        categoryService.update(this.movedElement);
      },
    },
    computed: {
      draggableEnabled() {
        if (this.topLevelCategoriesOnly)
          return true;

        if (this.filteredCategory != null && this.filteredCategory.id != null)
          return true;

        return false;
      }
    }
  }
</script>
