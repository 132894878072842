var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"toolbar-container mb-2",attrs:{"flat":"","height":"100"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"toolbar-datepicker"},[_c('DateRangePicker',{attrs:{"initialDates":_vm.dates},on:{"blur":_vm.updateDates}})],1)]),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Product","items":_vm.products,"item-text":"name","item-value":"id","clearable":"","loading":_vm.productsLoading,"search-input":_vm.search,"return-object":"","hide-details":"","cache-items":"","filter":_vm.customFilter},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),(item.category)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.category.name)+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"search-btn",attrs:{"block":"","type":"button","color":"success"},on:{"click":_vm.load}},[_vm._v(" SEARCH ")])],1)],1),(_vm.data.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"text-subtitle-2"},[_vm._v("Total Staff")]),_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.data.length))])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"text-subtitle-2"},[_vm._v("Total Quantity")]),_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.data.reduce(function (sum, staff) { return sum + staff.totalQuantitySold; }, 0))+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"text-subtitle-2"},[_vm._v("Total Revenue")]),_c('div',{staticClass:"text-h5"},[_vm._v(" £"+_vm._s(_vm.data.reduce(function (sum, staff) { return sum + staff.totalRevenue; }, 0).toFixed(2))+" ")])])],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isLoading,"items-per-page":-1,"single-expand":true,"show-expand":"","item-key":"staffName"},scopedSlots:_vm._u([{key:"item.totalRevenue",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.totalRevenue.toFixed(2))+" ")]}},{key:"item.revenuePercentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.revenuePercentage.toFixed(1))+"% ")]}},{key:"item.averageDiscount",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.averageDiscount.toFixed(2))+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"sales-detail pa-4"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Order Ref")]),_c('th',{staticClass:"text-right"},[_vm._v("Quantity")]),_c('th',{staticClass:"text-right"},[_vm._v("Price")])])]),_c('tbody',_vm._l((item.sales),function(sale){return _c('tr',{key:sale.orderRef},[_c('td',[_vm._v(_vm._s(_vm.formatDate(sale.saleDate)))]),_c('td',[_vm._v(_vm._s(sale.orderRef))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(sale.quantity))]),_c('td',{staticClass:"text-right"},[_vm._v("£"+_vm._s(sale.price.toFixed(2)))])])}),0)]},proxy:true}],null,true)})],1)])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }