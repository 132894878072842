<template>
  <div>
    <h2><v-checkbox v-on:click="onExtrasChange" v-model="includesExtras" label="Has Extras" style="margin-top: 0px;" hide-details="auto"></v-checkbox></h2>

    <div v-if="includesExtras" style="margin-top: 12px;">
      <v-row align="center">
        <v-col>
          <v-select :items="extraCategoriesList" item-text="name" v-model="extraCategoryId" item-value="id" label="Extra Category" />
        </v-col>
        <v-spacer/>
        <v-col>
          <v-btn type="button" color="primary" v-on:click="addExtra()">
            ADD EXTRAS
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <div v-for="(extraCategory, index) in extras" v-bind:key="index" class="extra-block">
        <!-- <h4><label>{{ extraCategory.name }}</label><v-icon v-on:click="remove(extra)" medium>mdi-delete</v-icon></h4> -->
        <h4>
          <v-checkbox v-on:click="selectExtras(extraCategory)" v-model="extraCategory.selected" :label="extraCategory.name" hide-details="auto" style="margin-top: 0px; flex: 1; font-weight: bold;"></v-checkbox>
          </h4>

        <div class="extra-list">
          <div v-for="(extra, index) in extraCategory.extras" v-bind:key="index" class="extra">
            <v-checkbox v-model="extra.selected" :label="extra.name" hide-details="auto" style="margin-top: 0px; flex: 1; font-weight: bold;"></v-checkbox>

            <v-checkbox v-model="extra.eposOnly" hide-details="auto" label="EPOS Only" style="margin-top: 0px; padding-left: 5px; padding-right: 5px;"></v-checkbox>

            <v-checkbox v-model="extra.free" hide-details="auto" label="Free" style="margin-top: 0px; padding-left: 5px; padding-right: 5px;"></v-checkbox>

            <v-checkbox v-model="extra.hasQty" hide-details="auto" label="Qty" style="margin-top: 0px; padding-left: 5px; padding-right: 5px;"></v-checkbox>
          </div>
        </div>

        <v-text-field v-model="extraCategory.noOfFree" label="# Of Free" />

        <v-text-field v-model="extraCategory.maximum" label="Maximum" />
      </div>

      
    </v-row>
   
  </div>
</template>

<script>
import { extraService, extraCategoryService } from '@/services';

export default {
  props: ['productExtras'],
  async created () {
    this.extraCategoriesList = await extraCategoryService.get(true);
  },
  data () {
    return {
      extras: [],
      includesExtras: false,

      extraCategoryId: null
    }
  },
  methods: {
    onExtrasChange: function() {
      this.extras = [];
    },
    selectExtras: function(extraCategory) {
      for (var extra of extraCategory.extras) {
        extra.selected = extraCategory.selected;
      }
    },
    remove: function(extra) {
      var idx = this.extras.indexOf(extra);
      this.extras.splice(idx);
    },
    getExtras: function() {
      var productExtras = this.extras.map(extraCategory => ({
        name: extraCategory.name,
        noOfFree: extraCategory.noOfFree == null ? 0 : extraCategory.noOfFree,
        maximum: extraCategory.maximum == null ? 0 : extraCategory.maximum,
        extraCategoryId: extraCategory.id,
        extras: extraCategory.extras.filter(e => e.selected).map(e => ({
          name: e.name,
          description: e.description,
          cost: e.price,
          free: e.free == null ? false : e.free,
          hasQty: e.hasQty == null ? false : e.hasQty,
          eposOnly: e.eposOnly == null ? false : e.eposOnly,
          extraId: e.id
        }))
      }));
      return productExtras;
    },
    addExtra: function() {
      if (this.extras.filter(e => e.id == this.extraCategoryId).length > 0)
        return;

      this.extras.push(this.extraCategoriesList.find(e => e.id == this.extraCategoryId));
      console.log(this.extraCategoryId)
      // Get our extras
    }
  },
  watch: {
    productExtras: function(val) {
      if (val.length > 0) {
        this.includesExtras = true;
        for (let extraCategory of this.extraCategoriesList) {
          var productExtraCategory = val.find(e => e.extraCategoryId == extraCategory.id);
          // This category has not been selected
          if (productExtraCategory == null)
            continue;

          console.log(productExtraCategory);
          extraCategory.noOfFree = productExtraCategory.noOfFree;
          extraCategory.maximum = productExtraCategory.maximum;

          for (let extra of extraCategory.extras) {
            var productExtra = productExtraCategory.extras.find(e => e.extraId == extra.id);
            if (productExtra == null)
              continue;

            extra.eposOnly = productExtra.eposOnly;
            extra.free = productExtra.free;
            extra.hasQty = productExtra.hasQty;
            extra.selected = true;
          }

          this.extras.push(extraCategory);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;
    flex-direction: row;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }

  .size-row {
    box-sizing: border-box;
    background: #FFF; 
    margin-bottom: 25px; 
    border-radius: 10px;
    padding: 10px;

    label {
      font-style: italic;
      display: flex;
      
      button {
        margin: 0px;
      }

      span {
        flex: 1;
      }
    }
  }

  .extra-block {
    padding: 10px;
    border: 1px solid #CECECE;
    width: 100%;
    
    h4 {
      border-bottom: 1px solid #CECECE;
      padding-bottom: 5px;

      display: flex;
      flex-direction: row;

      label {
        flex: 1;
      }
    }

    .extra-list {
      height: 150px;
      overflow-y: scroll;
      margin-top: 5px;
      padding-left: 10px;
      padding-right: 10px;

      .extra {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
</style>

