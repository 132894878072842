<template>
  <div>
    <v-toolbar flat class="toolbar-container mb-2" height='100'>
      <v-row>
        <v-col>
          <div class="toolbar-datepicker">
            <DateRangePicker :initialDates="dates" @blur="updateDates"  />
          </div>
        </v-col>

        <v-col v-if="stores.length > 1">
          <v-select :items="stores" item-text="name" v-model="selectedStoreId" item-value="id" />
        </v-col>
      </v-row>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-btn block class="search-btn" type="button" color="success" v-on:click="load">
          SEARCH
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
       <v-toolbar flat class="toolbar-container" height='100'>
          <div class="toolbar-content">
            <div class="toolbar-datepicker">
              <DateRangePicker :initialDates="dates" @blur="updateDates"  />
            </div>

            <v-select :items="stores" item-text="name" v-model="selectedStoreId" item-value="id" />

            <v-spacer></v-spacer>

            <v-btn class="search-btn" type="button" color="success" v-on:click="load">
              SEARCH
            </v-btn>
          </div>
       </v-toolbar>
    </v-row> -->

     <v-row>
       <v-data-table 
        :headers="headers" :items="data" 
        :loading="isLoading" :items-per-page="-1"
        item-key="refundedAt" class="elevation-1 mt-5 flex-grow-1">

        <template v-slot:[`item.refundedAt`]="{ item }">
          {{ moment(item.refundedAt).format("DD/MM/yyyy HH:mm:ss") }}
        </template>
        
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import { reportService } from '@/services';
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'Refunds',
  components: {
    DateRangePicker,
  },
  data: vm => ({
    dates: [new Date().toISOString().substr(0, 10)],

    stores: [],
    selectedStoreId: null,

    isLoading: false,
    data: [],
    headers: [
      { text: 'Refunded At', align: 'start', sortable: true, filterable: true, value: 'refundedAt' },
      { text: 'Amount', align: 'start', sortable: true, filterable: true, value: 'refundedAmount' },
      { text: 'Reason', align: 'start', sortable: true, filterable: true, value: 'refundedReason' },
      { text: 'Staff', align: 'start', sortable: true, filterable: true, value: 'staff' },
    ],
  }),
  async created () {
    if (userService.isStaff()) {
      router.push('/products');
    }
    
    this.stores = await reportService.getStores();
    this.stores.unshift({
      id: null,
      name: 'All'
    });


    this.load();
  },
 methods: {
    updateDates(newDates) {
      this.dates = newDates;
    },
    async load() {
      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1)
        endDate = this.dates[1];

      this.isLoading = true;
      this.data = await reportService.getRefundReport(startDate, endDate, this.selectedStoreId);
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar-container {
    background: #FFF !important;
    padding: 10px;
    height: auto;
    border-radius: 8px;

    border: 1px solid lighten(#7B5CD8, 30);
  }

  .toolbar-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toolbar-datepicker {
      width: 250px;
    }
  }

</style>
