<template>
  <div>
    <div class="d-flex justify-end mb-5">
      <router-link class="logout-link" to="apps/manage">
        <v-btn class="float-right" type="button" color="primary">
          ADD NEW APP
        </v-btn>
      </router-link>
    </div>

    <SearchBar label='Search for app' @search="search = $event"/>
  
    <v-data-table 
      :headers="headers" :items="apps" :search="search" 
      :loading="isLoading"
      item-key="id" class="elevation-1 mt-5">
       <template v-slot:[`item.actions`]="{ item }">
        <router-link class="logout-link"  :to="`/apps/manage/${item.id}`">
          <v-icon small class="mr-2">mdi-pencil</v-icon>
        </router-link>
        <v-icon small @click="deleteApp(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import { authHeader } from '@/helpers';
import SearchBar from '@/components/SearchBar.vue'
import ProgressIndicator from '@/components/ProgressIndicator.vue'

export default {
  name: 'Apps',
  components: {
    SearchBar,
    ProgressIndicator
  },
  data: () => ({
    isLoading: false,
    apps: [],
    search: "",
    headers: [
      { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
      { text: 'Address Line 1', align: 'start', sortable: true, filterable: true, value: 'addressLine1' },
      { text: 'Postcode', align: 'start', sortable: true, filterable: true, value: 'postcode' },
      { text: 'Phone', align: 'start', sortable: true, filterable: true, value: 'phone' },
      { text: 'Actions' , align: 'end', value: 'actions' }
    ]
  }),
  created () {
    this.load();
  },
  methods: {
    load: function() {
      this.isLoading = true;
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      fetch(`${process.env.VUE_APP_APIURL}/app/get`, requestOptions)
        .then((response) => response.json())
        .then(responseJson => {
          this.isLoading = false;
          this.apps = responseJson;
        });
    },

    deleteApp: function(app) {
       const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
      };

      fetch(`${process.env.VUE_APP_APIURL}/app/get?id=` + app.id, requestOptions)
        .then((response) => {
          this.load();
        });
    }
  }
}
</script>

