import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";
import { userService } from '../services'

Vue.use(Vuex)

//import { authentication } from './authentication.module';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

export default new Vuex.Store({
  state: initialState,
  actions: {
    login({ dispatch, commit }, { username, password }) {
      commit('loginRequest', { username });

      userService.login(username, password).then(user => {
        commit('loginSuccess', user);
        if (user.role == "Staff") {
          router.push('/products');
        }
        else {
          router.push('/');
        }
      }, error => {
          commit('loginFailure', error);
        }
      );
    },
    logout({ commit }) {
      userService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true, loginFailed: false };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true, loginFailed: false };
      state.user = user;
    },
    loginFailure(state) {
      state.status = { loginFailed: true };
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    }
  }
})


// export default new Vuex.Store({
//   state: {

//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//     authentication,
//   }
// })


