<template>
  <v-menu
    ref="datePicker"
    v-model="datePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        label="Date(s)"
        persistent-hint
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dates"
      range
      no-title
      @input="menu1 = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    name: 'DateRangePicker',
    props: ['initialDates'],
    data: function () {
      return {
        datePicker: false,
        dates: this.initialDates,
      }
    },
    computed: {
      dateRangeText () {
        var newDatesArray = JSON.parse(JSON.stringify(this.dates));
        for (var i = 0; i < newDatesArray.length; i++) {
          newDatesArray[i] = this.formatDate(newDatesArray[i]);
        }
        this.$emit('blur', this.dates);
        return newDatesArray.join(' ~ ');
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    },
  }
</script>

<style lang="scss" scoped>
 
</style>

