<template>
  <div>
    <h2><v-checkbox v-on:click="onSizesChange" v-model="includesSizes" label="Has Sizes" style="margin-top: 0px;" hide-details="auto"></v-checkbox></h2>

    <v-btn type="button" color="primary" v-on:click="addEmptySize()" v-if="includesSizes" style="margin-top: 12px;">
      ADD SIZE
    </v-btn>

    <v-form class="form" ref="form" style="max-width:100%; background: transparent" v-if="includesSizes" >
      <v-row>
        <v-col>
          <div v-for="(size, index) in sizes" :key="index" class="row size-row">
            <v-row>
              <v-col>
                <label>
                  <span>Size</span>
                  <v-icon v-on:click="remove(size)" medium>mdi-delete</v-icon>
                  <v-icon v-on:click="addAdditionalEmptySize(size)" medium>mdi-plus</v-icon>
                </label>
                <v-text-field v-model="size.size" />
              </v-col>
              <v-col>
                <label>Price</label>
                <v-text-field v-model="size.surplus" />
              </v-col>
              <v-col>
                <label>Extra Surplus</label>
                <v-text-field v-model="size.extraSurplus" />
              </v-col>
              <v-col>
                <label>Instore</label>
                <v-text-field v-model="size.instoreSurplus"/>
              </v-col>
            </v-row>
            <div v-if="size.sizes != null && size.sizes.length > 0">
              <v-row v-for="(additionalSize, sizeIndex) in size.sizes" :key="sizeIndex">
                <v-col>
                  <label>
                    <span>Option</span>
                    <v-icon v-on:click="removeAdditionalSize(size, additionalSize)" medium>mdi-delete</v-icon>
                  </label>
                  <v-text-field v-model="additionalSize.option" />
                </v-col>
                <v-col>
                  <label>Surplus</label>
                  <v-text-field v-model="additionalSize.surplus" />
                </v-col>
              </v-row>
              </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>

export default {
  props: ['productSizes'],
  async created () {

  },
  data () {
    return {
      sizes: [],
      includesSizes: false
    }
  },
  methods: {
    onSizesChange: function() {
      this.sizes = [];
      if (this.includesSizes) {
        this.addEmptySize();
      }
    },
    getSizes: function() {
      return this.sizes;
    },
    addEmptySize: function() {
      this.sizes.push({
        size: "",
        surplus: null,
        extraSurplus: 0,
        instoreSurplus: 0,
        sizes: []
      })
    },

    remove: function(size) {
      var idx = this.sizes.indexOf(size);
      this.sizes.splice(idx);
      // this.sizes = this.sizes.filter(s => s.size !== size);
    },

    addAdditionalEmptySize: function(size) {
      if (size.sizes == null) { size.sizes = []; }

      size.sizes.push({
        option: "",
        surplus: null
      })

      this.$forceUpdate();
    },

    removeAdditionalSize: function(size, additionalSize) {
      var idx = size.sizes.indexOf(additionalSize);
      size.sizes.splice(idx);

      this.$forceUpdate();
    }
  },
  watch: {
    productSizes: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.includesSizes = true;
          var sizes = JSON.parse(JSON.stringify(this.productSizes));
          this.sizes = sizes.sort((a, b) => a.surplus - b.surplus);
          for (let size of this.sizes) {
            if (size.sizes != null)
              size.sizes = size.sizes.sort((a, b) => a.surplus - b.surplus);
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;
    flex-direction: row;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }

  .size-row {
    box-sizing: border-box;
    background: #FFF; 
    margin-bottom: 25px; 
    border-radius: 10px;
    padding: 10px;

    label {
      font-style: italic;
      display: flex;
      
      button {
        margin: 0px;
      }

      span {
        flex: 1;
      }
    }
  }
</style>

