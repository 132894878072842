import { authHeader } from '@/helpers';

export const appService = {
  get,
  update
};

async function get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/app/getuserapp`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function update(app) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(app)
  };

  return fetch(`${process.env.VUE_APP_APIURL}/app/put`, requestOptions)
}
