var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"toolbar-container mb-2",attrs:{"flat":"","height":"100"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"toolbar-datepicker"},[_c('DateRangePicker',{attrs:{"initialDates":_vm.dates},on:{"blur":_vm.updateDates}})],1)]),(_vm.stores.length > 1)?_c('v-col',[_c('v-select',{attrs:{"items":_vm.stores,"item-text":"name","item-value":"id"},model:{value:(_vm.selectedStoreId),callback:function ($$v) {_vm.selectedStoreId=$$v},expression:"selectedStoreId"}})],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"export-btn",attrs:{"block":"","type":"button","color":"primary"},on:{"click":_vm.exportReport}},[_vm._v(" EXPORT ")])],1),_c('v-col',[_c('v-btn',{staticClass:"search-btn",attrs:{"block":"","type":"button","color":"success"},on:{"click":_vm.load}},[_vm._v(" SEARCH ")])],1)],1),_c('v-row',[_c('v-col',[_c('div',[_c('TakingsChart',{attrs:{"data":_vm.data}})],1)])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 mt-5 flex-grow-1",attrs:{"headers":_vm.computedHeaders,"items":_vm.data,"loading":_vm.isLoading,"dense":"","item-key":"takingsDate"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[(_vm.data != null && _vm.data.length > 0)?_c('th',{attrs:{"colspan":headers.length}},[_vm._v(" Total: £"+_vm._s(_vm.data.map(function (d) { return d.total; }).reduce(function (x, y) { return x + y; }).toFixed(2))+" | Cash: £"+_vm._s(_vm.data.map(function (d) { return d.cash; }).reduce(function (x, y) { return x + y; }).toFixed(2))+" | Card: £"+_vm._s(_vm.data.map(function (d) { return d.card; }).reduce(function (x, y) { return x + y; }).toFixed(2))+" | Net Profit: £"+_vm._s(_vm.data.map(function (d) { return d.netProfit; }).reduce(function (x, y) { return x + y; }).toFixed(2))+" ")]):_vm._e()])])]}},{key:"item.takingsDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.takingsDate).format("DD/MM/yyyy"))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.total.toFixed(2))+" ")]}},{key:"item.totalDiscount",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.totalDiscount.toFixed(2))+" ")]}},{key:"item.cash",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.cash.toFixed(2))+" ")]}},{key:"item.card",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.card.toFixed(2))+" ")]}},{key:"item.vat",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.vat.toFixed(2))+" ")]}},{key:"item.netProfit",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.netProfit.toFixed(2))+" ")]}},{key:"item.totalExVAT",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.totalExVAT.toFixed(2))+" ")]}},{key:"item.totalBuyingPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.totalBuyingPrice.toFixed(2))+" ")]}},{key:"item.serviceChargeCash",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.serviceChargeCash.toFixed(2))+" ")]}},{key:"item.serviceChargeCard",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.serviceChargeCard.toFixed(2))+" ")]}},{key:"item.serviceChargeTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.serviceChargeTotal.toFixed(2))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }