<script>
import { Bar } from 'vue-chartjs'
import moment from 'moment'

export default {
  extends: Bar,
  props: ['data'],
  mounted () {
    this.loadChart();
  },
  methods: {
    loadChart() {
      this.renderChart({
        labels: this.data.map(d => moment(d.takingsDate).format("DD/MM/yyyy")),
        datasets: [
          {
            label: 'Takings',
            backgroundColor: '#4caf50',
            data: this.data.map(d => d.total)
          },
          {
            label: 'Net Profit',
            backgroundColor: '#000',
            data: this.data.map(d => d.netProfit)
          },
          {
            label: 'Cash',
            backgroundColor: '#F9039E',
            data: this.data.map(d => d.cash)
          },
           {
            label: 'Card',
            backgroundColor: '#0DA0D9',
            data: this.data.map(d => d.card)
          },
          {
            label: 'Discounts',
            backgroundColor: '#f87979',
            data: this.data.map(d => d.totalDiscount)
          },
          {
            label: 'VAT',
            backgroundColor: '#7b5cd8',
            data: this.data.map(d => d.vat)
          },
          {
            label: '# Sales',
            backgroundColor: 'orange',
            data: this.data.map(d => d.noOfSales)
          },
          {
            label: '# Items',
            backgroundColor: 'blue',
            data: this.data.map(d => d.noOfItems)
          },
        ]
      }, {
        display: true,
        responsive: true, 
        maintainAspectRatio: false, 
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                return '£' + value;
              }
            }
          }]
        }
      })
    }
  },
  watch: {
    data: function(newVal, oldVal) {
      this.loadChart();
    }
  }
}
</script>