<template>
  <div>
    <h2><v-icon v-on:click="goBack" color="primary" medium>mdi-arrow-left</v-icon><span>Add Extra Category</span></h2>
    <v-form class="form" ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
      <v-text-field v-model="name" label="Name" :rules="[v => !!v || 'Name is required']" />

      <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
        SUBMIT
      </v-btn> 

    </v-form>
  </div>
</template>

<script>

import { extraCategoryService } from '@/services';

export default {
  data () {
    return {
      id: null,
      name: '',

      submitted: false,
      valid: false,
    }
  },
  async created () {
    var id = this.$route.params.id;
    if (id != null) {
      var dbExtraCategory = await extraCategoryService.getById(id);

      this.id = dbExtraCategory.id;
      this.name = dbExtraCategory.name;
    }
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    async handleSubmit (e) {
      if (!this.$refs.form.validate())
        return;

      this.submitted = true;

      var dto = {
        id: this.id,
        name: this.name,
      }

      console.log(dto);

      var response = null;
      if (dto.id != null)
        response = await extraCategoryService.update(dto);
      else
        response = await extraCategoryService.add(dto);

      if (response.status != 200) {
        alert("Error saving");
        return;
      }

      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }
</style>

