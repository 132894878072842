<template>
  <div>
    <v-row justify="center">
      <v-toolbar flat class="toolbar-container" height='100'>
        <div class="toolbar-content">
          <div class="toolbar-datepicker">
            <DateRangePicker :initialDates="dates" @blur="updateDates"  />
          </div>

          <v-select :items="stores" item-text="name" v-model="selectedStoreId" item-value="id" />

          <v-spacer></v-spacer>

          <v-btn class="search-btn" type="button" color="success" v-on:click="load">
            SEARCH
          </v-btn>
        </div>
      </v-toolbar>
    </v-row>

    <v-row class="titles">
      <v-col>
        <h2>Best Sellers</h2>
      </v-col>
       <v-col>
        <h2>Worst Sellers</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div>
          <BestSellersPieChart :data="bestSellers"/>
        </div>
      </v-col>
       <v-col>
        <div>
          <BestSellersPieChart :data="worstSellers"/>
        </div>
      </v-col>
    </v-row>

    <v-row>
        <v-col>
          <v-data-table 
            :headers="headers" :items="bestSellers" 
            :loading="isLoading"
            item-key="productName" class="elevation-1 mt-5 flex-grow-1">
          </v-data-table>
        </v-col>
        <v-col>
          <v-data-table 
            :headers="headers" :items="worstSellers" 
            :loading="isLoading"
            item-key="productName" class="elevation-1 mt-5 flex-grow-1">
          </v-data-table>
        </v-col>
    </v-row>
   
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import BestSellersPieChart from '@/components/charts/BestSellersPieChart.vue';
import { reportService } from '@/services';
import moment from 'moment'
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'Takings',
  components: {
    DateRangePicker,
    BestSellersPieChart
  },
  data: vm => ({
    // dates: [new Date().toISOString().substr(0, 10)],
    dates: [moment().add(-12, 'M').toISOString().substr(0, 10), moment().toISOString().substr(0, 10)],

    stores: [],
    selectedStoreId: null,

    isLoading: false,
    headers: [
      { text: 'Product', align: 'start', sortable: true, filterable: true, value: 'productName' },
      { text: 'Quantity', align: 'start', sortable: true, filterable: true, value: 'quantity' },
    ],
    bestSellers: [],
    worstSellers: [],
  }),
  async created () {
    this.stores = await reportService.getStores();
    this.stores.unshift({
      id: null,
      name: 'All'
    });

    this.load();

    if (userService.isStaff()) {
      router.push('/products');
    }
  },
  methods: {
    updateDates(newDates) {
      this.dates = newDates;
    },
    async load() {
      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1)
        endDate = this.dates[1];

      this.isLoading = true;
      this.bestSellers = await reportService.getBestSellersReport(startDate, endDate, this.selectedStoreId);
      this.worstSellers = await reportService.getWorstSellersReport(startDate, endDate, this.selectedStoreId);
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar-container {
    background: #FFF !important;
    padding: 10px;
    height: auto;
    border-radius: 8px;

    border: 1px solid lighten(#7B5CD8, 30);
  }

  .toolbar-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toolbar-datepicker {
      width: 250px;
    }
  }

  .sub-total {
    ul {
      width: 100%;
      list-style-type: none;
      margin: 0;
      padding: 10px 10px;
    }
  
  }

  .titles {
    h2 {
      text-align: center;
      padding: 20px;
      border-bottom: 2px solid #7b5cd8;
    }
  }
</style>
