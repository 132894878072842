<template>
  <div>
    <router-link to="extras/categories/manage" style="padding-bottom: 10px; display: block;">
      <v-btn type="button" color="primary">
        ADD EXTRA CATEGORY
      </v-btn>
    </router-link>

    <SearchBar ref="searchBar" label='Search for extra category' @search="filterTable($event)"/>

    <v-data-table 
      :headers="headers" :items="categories" :search="search" 
      :loading="isLoading" :items-per-page="-1"
      item-key="id" class="elevation-1 mt-5" 
      show-select :single-select="true" v-model="selected">

      <template v-slot:[`item.actions`]="{ item }">
        <router-link :to="`extras/categories/manage/${item.id}`">
          <v-icon small class="ml-5">mdi-pencil</v-icon>
        </router-link>
        <v-icon class="ml-5" small @click="remove(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { extraCategoryService } from '@/services';
  import SearchBar from '@/components/SearchBar.vue';
  
  export default {
    name: 'ExtraCategories',
    props: ['onExtraCategorySelected'],
    components: {
      SearchBar
    },
    data: () => ({
      isLoading: false,
      categories: [],
      selected: [],
      search: "",
      headers: [
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
        { text: 'Actions' , align: 'end', value: 'actions' }
      ],
    }),
    created () {
      this.load();
    },
    methods: {
      load: async function() {
        this.isLoading = true;
        this.categories = await extraCategoryService.get();
        this.isLoading = false;
      },
      filterTable: function(search) {
        this.search = search;
      },
      remove: async function(category) {
        this.$confirm(
        {
          message: `Are you sure you want to delete this extra category?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              this.isLoading = true;
              var response = await extraCategoryService.remove(category.id);
              this.isLoading = false;
              if (response.status != 200) {
                alert("Error deleting");
                return;
              }

              this.load();
            }
          }
        })
      },
    }, 
    watch: {
      selected(newSelected, oldSelected) {
        if (newSelected.length == 0)
          this.onExtraCategorySelected(null);  
        else
          this.onExtraCategorySelected(newSelected[0]);
        
      }
    }
  }
</script>
