<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="loggedIn" :height="78" class="no-print">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <!-- <v-img alt="AC Logo" class="shrink mr-2" contain
          src="https://appcompanion.co.uk/wp-content/uploads/2019/11/cropped-trans-e1573669144645.png"
          transition="scale-transition" width="40"/> -->
        <!-- <button class="menu-btn"><v-icon medium>mdi-menu</v-icon></button> -->
        <h3>App Companion - {{ title }}</h3>
      </div>

      <v-spacer></v-spacer>

      <div v-if="loggedIn">
        <router-link class="logout-link" to="/login"><v-icon medium>mdi-logout</v-icon></router-link>
      </div>
    </v-app-bar>

    <v-navigation-drawer class="navigation-drawer no-print" v-if="loggedIn" app :width="width" v-model="drawer">
      <div class="panels">
        <div class="primary-panel">
          <router-link class="panel-link" to="/apps" v-if="isAdmin">
            <v-icon medium>mdi-cog</v-icon>
            <span>Apps</span>
          </router-link>

          <router-link class="panel-link" to="/suppliers">
            <v-icon medium>mdi-format-list-checks</v-icon>
            <span>Suppliers</span>
          </router-link>

          <router-link class="panel-link" to="/categories">
            <v-icon medium>mdi-format-list-checks</v-icon>
            <span>Categories</span>
          </router-link>

          <router-link class="panel-link" to="/products">
            <v-icon medium>mdi-cogs</v-icon>
            <span>Products</span>
          </router-link>

          <router-link class="panel-link" to="/inventory">
            <v-icon medium>mdi-cog-refresh</v-icon>
            <span>Inventory</span>
          </router-link>

          <router-link class="panel-link" to="/reports" v-if="!isStaff">
            <v-icon medium>mdi-file-chart</v-icon>
            <span>Reports</span>
          </router-link>

          <router-link class="panel-link" to="/promotions">
            <v-icon medium>mdi-currency-usd-off</v-icon>
            <span>Promotions</span>
          </router-link>

          <router-link class="panel-link" to="/settings">
            <v-icon medium>mdi-cog</v-icon>
            <span>Settings</span>
          </router-link>

          <router-link class="panel-link" to="/password-reset">
            <v-icon medium>mdi-cog</v-icon>
            <span>Change Password</span>
          </router-link>

        </div>

        <div class="secondary-panel" v-if="activeRoute != null">
          <router-link v-for="route in activeRoute.routes" :key="route.name" :to="route.url" class="panel-link">
            <v-icon medium>mdi-file-chart</v-icon>
            <!-- <v-icon medium>{{ route.icon }}</v-icon> -->
            <span>{{ route.name }}</span>
          </router-link>
        </div>
      </div>
    
    </v-navigation-drawer>

    <!-- <v-main class="mt-10 content" v-bind:class="{ 'pl-10': loggedIn }"> -->
    <v-main>
      <vue-confirm-dialog></vue-confirm-dialog>
      <div class="pa-5 content">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>

import { authHeader } from './helpers';
import { userService } from '@/services';

export default {
  name: 'App',
  data() {
    return {
      title: "Home",
      isAdmin: false,
      isStaff: false,
      drawer: false,
      group: null,

      width: 100,
      subRoutes: [
        { name: 'reports', url: '/reports', icon: "mdi-file-chart", routes: [
          { name: 'Takings', url: '/reports/takings' },
          { name: 'Orders', url: '/reports/orders' },
          { name: 'Online Orders', url: '/reports/onlineorders' },
          { name: 'Staff Product Sales', url: '/reports/staffproductsales' },
          { name: 'Refunds', url: '/reports/refunds' },
          { name: 'Deleted Audit', url: '/reports/audit' },
          { name: 'Current Stock', url: '/reports/currentstock' },
          { name: 'Ordering', url: '/reports/ordering' },
          { name: 'Low Stock', url: '/reports/lowstock' },
          { name: 'Write Off', url: '/reports/writeoff' },
          { name: 'Best/Worst Sellers', url: '/reports/bestsellers' },
          { name: 'Timesheets', url: '/reports/timesheets' },
        ]},
        { name: 'products', url: '/products', icon: "mdi-file-chart", routes: [
          { name: 'Products', url: '/products/list' },
          { name: 'Extras', url: '/products/extras' },
          // { name: 'Orders', url: '/reports/orders' },
          // { name: 'Refunds', url: '/reports/refunds' },
          // { name: 'Current Stock', url: '/reports/currentstock' },
          // { name: 'Ordering', url: '/reports/ordering' },
          // { name: 'Low Stock', url: '/reports/lowstock' },
          // { name: 'Write Off', url: '/reports/writeoff' },
          // { name: 'Best/Worst Sellers', url: '/reports/bestsellers' },
          // { name: 'Timesheets', url: '/reports/timesheets' },
        ]},
      ],
      activeRoute: null
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.status.loggedIn;
    },
  },
  watch: {
    $route(to, from) {
      var expandableRoute = this.subRoutes.find(r => r.url == to.path);
      var childRoute = null;
      if (this.activeRoute != null)
        childRoute = this.activeRoute.routes.find(r => r.url == to.path);

      if (expandableRoute) {
        this.$router.push({ path: expandableRoute.routes[0].url });
        this.activeRoute = expandableRoute;
        
        this.width = 200;
      } else if (childRoute == null) {
        this.activeRoute = null;
        this.width = 100;
      }

      this.title = to.name;
      this.isAdmin = userService.isAdmin();
      this.isStaff = userService.isStaff();
    }
  },
  methods: {
    toggleDrawer: function() {

    }
  }
};
</script>

<style lang="scss">
  @import "./breakpoints.scss";

  .menu-btn {
    margin-right: 10px;
    padding-right: 10px;
  }

  .logout-link {
    color: #FFF;
    font-weight: bold;
    text-decoration: none;
  }

  .navigation-drawer {
    background: #F0F0F0 !important;

    .panels {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

      .panel-link {
          padding: 15px 10px;
          display: flex;
          flex-direction: column;
          text-decoration: none;
          text-align: center;
          border: 0;
          outline: 0;
          margin: 0px !important;
        }

      .primary-panel {
        width: 100px;
        overflow: hidden;
        overflow-y: auto;
        
        
        .panel-link {
          color: #58595B;

          i {
            color: #58595B;
          }
        }

        .router-link-active {
          background-color: #58595B;
          color: #FFF;

          i {
            color: #FFF;
          }
        }
      }

      .secondary-panel {
        width: 100px;
        background: #58595B;
        overflow: hidden;
        overflow-y: auto;

        .panel-link {
          color: #FFF;

          i {
            color: #FFF;
          }
        }

        .router-link-active {
          background-color: #878787;
          color: #FFF;

          i {
            color: #FFF;
          }
        }
      }

      
  }

  .print {
    display: none;
    width: 100%;

    table {
      margin-top: 20px;
      width: 100%;
      page-break-after: always;

      .stock-column {
        width: 100px;
        text-align: center;
      }

      tr, td {
        border: 1px solid #000;
        padding: 5px;
      }
    }

  }

  @media print {
    .no-print {
      display: none;
    }

    .print {
      display: block;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
    
    .filter {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: #FFF;
      padding: 10px;
      border-radius: 10px;

      border: 1px solid lighten(#7B5CD8, 30);
      justify-content: center;

      margin-top: 10px;
    
      @include md {
        margin-top: 0px;
        margin-left: 10px;
      }
    }
  }
</style>
