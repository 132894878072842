var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"toolbar-container mb-2",attrs:{"flat":"","height":"100"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"toolbar-datepicker"},[_c('DateRangePicker',{attrs:{"initialDates":_vm.dates},on:{"blur":_vm.updateDates}})],1)]),(_vm.stores.length > 1)?_c('v-col',[_c('v-select',{attrs:{"items":_vm.stores,"item-text":"name","item-value":"id"},model:{value:(_vm.selectedStoreId),callback:function ($$v) {_vm.selectedStoreId=$$v},expression:"selectedStoreId"}})],1):_vm._e(),_c('v-col',[_c('v-select',{attrs:{"label":"Account","items":['All', 'Card', 'Cash']},model:{value:(_vm.selectedAccount),callback:function ($$v) {_vm.selectedAccount=$$v},expression:"selectedAccount"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"search-btn",attrs:{"block":"","type":"button","color":"success"},on:{"click":_vm.load}},[_vm._v(" SEARCH ")])],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 mt-5 flex-grow-1",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isLoading,"items-per-page":-1,"single-expand":true,"show-expand":"","item-key":"orderRef"},scopedSlots:_vm._u([{key:"item.orderPlacedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.orderPlacedAt).format("DD/MM/yyyy HH:mm:ss"))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.total.toFixed(2))+" ")]}},{key:"item.isDelivery",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isDelivery == true ? 'Delivery' : 'Collection')+" ")]}},{key:"item.option",fn:function(ref){
var item = ref.item;
return [(item.account == 'Cash')?_c('v-btn',{attrs:{"type":"button","color":"red"},on:{"click":function($event){return _vm.deleteOrder(item)}}},[_vm._v(" DELETE ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"sub-total"},[_c('ul',_vm._l((item.items),function(product){return _c('li',{key:product.id},[_vm._v(_vm._s(product.name)+" - £"+_vm._s(product.cost.toFixed(2)))])}),0)])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }