<template>
  <div>
    <div class="no-print">
      <v-row>
        <v-data-table 
          :headers="headers" :items="data" 
          :loading="isLoading"
          group-by="group" :items-per-page="-1"
          item-key="refundedAt" class="elevation-1 mt-5 flex-grow-1">

          <template v-slot:[`item.buyingPrice`]="{ item }">
            £{{ item.buyingPrice.toFixed(2) }}
          </template>

          <template v-slot:[`item.sellingPrice`]="{ item }">
            £{{ item.sellingPrice.toFixed(2) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="remove(item)">mdi-delete</v-icon>
          </template>

        </v-data-table>
      </v-row>
    </div>

    <div class="print">
      <div v-for="(group, index) in groupedData" v-bind:key="index" style="width: 100%">
        <h1>{{ group[0].group }}</h1>
        <table style="width: 100%">
          <thead>
            <tr>
              <th>Item</th>
              <th class="stock-column">Current Stock</th>
              <th class="stock-column">Min Stock</th>
              <th class="stock-column">Max Stock</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in group" v-bind:key="item.id">
              <td>{{ item.name }}</td>
              <td class="stock-column">{{ item.currentStock }}</td>
              <td class="stock-column">{{ item.minStock }}</td>
              <td class="stock-column">{{ item.maxStock }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import { reportService, productService } from '@/services';
import { userService } from '@/services';
import router from "../../router";

const _ = require("lodash"); 

export default {
  
  name: 'Refunds',
  components: {
    DateRangePicker,
  },
  data: vm => ({
    dates: [new Date().toISOString().substr(0, 10)],

    isLoading: false,
    data: [],
    groupedData: [],
    headers: [
      { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
      { text: 'Description', align: 'start', sortable: true, filterable: true, value: 'description' },
      { text: 'Buying Price', align: 'start', sortable: true, filterable: true, value: 'buyingPrice' },
      { text: 'Selling Price', align: 'start', sortable: true, filterable: true, value: 'sellingPrice' },
      { text: 'Current Stock', align: 'start', sortable: true, filterable: true, value: 'currentStock' },
      { text: 'Min Stock', align: 'start', sortable: true, filterable: true, value: 'minStock' },
      { text: 'Max Stock', align: 'start', sortable: true, filterable: true, value: 'maxStock' },
      { text: 'Actions' , align: 'end', value: 'actions' }
    ],
  }),
  created () {
    if (userService.isStaff()) {
      router.push('/products');
    }
    
    this.load();
  },
  methods: {
    async load() {
      this.isLoading = true;
      this.data = await reportService.getLowStockReport();

      this.groupedData = _.groupBy(this.data, "group");

      this.isLoading = false;
    },

    async remove(item) {
      this.isLoading = true;
      await productService.remove(item.id);
      this.isLoading = false;

      this.load();
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>
