var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mb-5"},[_c('v-spacer'),_c('router-link',{attrs:{"to":"/promotions/manage"}},[_c('v-btn',{attrs:{"type":"button","color":"primary"}},[_vm._v(" ADD NEW PROMOTION ")])],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 mt-5 flex-grow-1",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isLoading,"item-key":"refundedAt"},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startDate == null ? '' : _vm.moment(item.startDate).format("DD/MM/yyyy"))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.endDate == null ? '' : _vm.moment(item.endDate).format("DD/MM/yyyy"))+" ")]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticStyle:{"margin-top":"0px","margin-bottom":"0px"},attrs:{"readonly":"","hide-details":"false"},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"logout-link",attrs:{"to":("/promotions/manage/" + (item.id))}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }