<template>
  <div>
    <div class="d-flex mb-5">
      <v-btn type="button" color="primary" v-on:click="exportExcel()">
        EXPORT
      </v-btn>
    </div>

    <v-row>
      <v-data-table 
        :headers="headers" :items="data" 
        :loading="isLoading" :items-per-page="-1"
        show-expand :expanded.sync="expanded" :single-expand="true"
        item-key="supplier" class="elevation-1 mt-5 flex-grow-1">

       <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <table class="sub-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Min Stock</th>
                  <th>Stock</th>
                  <th>Total</th>
                  <th>Weekly Average</th>
                  <th>Jan/Mar</th>
                  <th>Apr/Jun</th>
                  <th>Jul/Sep</th>
                  <th>Oct/Dec</th>
                </tr>
              </thead>
              <tr :class="{ 'red-row' : product.isLowStock }" v-for="product in item.products" v-bind:key="product.id">
                <td>{{ product.name }}</td>
                <td>{{ product.minStock }} </td>
                <td>{{ product.currentStockFormatted }}</td>
                <td>£{{ product.total.toFixed(2) }}</td>
                <td>{{ product.weeklyQtyAverage }}</td>
                <td>{{ product.averages[0].qtyWeeklyTotal }}</td>
                <td>{{ product.averages[1].qtyWeeklyTotal }}</td>
                <td>{{ product.averages[2].qtyWeeklyTotal }}</td>
                <td>{{ product.averages[3].qtyWeeklyTotal }}</td>
              </tr>
            </table>
          </td>
      
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import { reportService } from '@/services';
import { b64toBlob } from '@/helpers'
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'Ordering',
  data: vm => ({
    isLoading: false,
    data: [],
    expanded: [],
    headers: [
      { text: 'Supplier', align: 'start', sortable: true, filterable: true, value: 'supplier' },
      // { text: 'Stock #', align: 'start', sortable: true, filterable: true, value: 'totalStock' },
      // { text: 'Total', align: 'start', sortable: true, filterable: true, value: 'total' },
    ],
  }),
  created () {
    if (userService.isStaff()) {
      router.push('/products');
    }
    
    this.load();
  },
  methods: {
    async load() {
      this.isLoading = true;
      this.data = await reportService.getOrderingReport();
      for (let supplier of this.data)
        supplier.products = supplier.products.sort((a, b) => a.currentStock - b.currentStock || a.name.localeCompare(b.name));

        
      this.isLoading = false;
    },

    exportExcel: async function() {
      this.isLoading = true;
      var response = await reportService.exportOrderingStockReport();
      if (response.status != 200) {
        alert("Error exporting");
        return;
      }

      var base64 = await response.json();
      const blob = b64toBlob(base64, 'application/vnd.ms-excel');
      saveAs(blob, 'OrderingStock.xls');

      this.isLoading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .sub-table {
    width: 50%;
    
    th {
      text-align: left;
      padding-top: 5px;
    }

    tr {
      td {
        border-bottom: 1px solid #CECECE;
      }
    }
  }

  .red-row {
    background: #ffc107;
  }
</style>
