var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mb-5"},[_c('v-btn',{attrs:{"type":"button","color":"primary"},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v(" EXPORT ")])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 mt-5 flex-grow-1",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isLoading,"items-per-page":-1,"show-expand":"","expanded":_vm.expanded,"single-expand":true,"item-key":"category"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header.total",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.data.length > 0)?_c('span',[_vm._v(" (£"+_vm._s(Math.round(_vm.data.map(function (d) { return d.total; }).reduce(function (sum, x) { return sum + x; }) * 100) / 100)+") ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.total.toFixed(2))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{staticClass:"sub-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Product")]),_c('th',[_vm._v("Stock")]),_c('th',[_vm._v("Total")])])]),_vm._l((item.products),function(product){return _c('tr',{key:product.id},[_c('td',[_vm._v(_vm._s(product.name))]),_c('td',[_vm._v(_vm._s(product.currentStockFormatted))]),_c('td',[_vm._v("£"+_vm._s(product.total.toFixed(2)))])])})],2)])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }