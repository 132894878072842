import { authHeader } from '@/helpers';

export const promotionService = {
  get,
  getById,
  update,
};

async function get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/promotion/get`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/promotion/getbyid?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}


async function update(promotion) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(promotion)
  };

  return fetch(`${process.env.VUE_APP_APIURL}/promotion/update`, requestOptions)
}

// async function remove(id) {
//   const requestOptions = {
//     method: 'DELETE',
//     headers: authHeader(),
//   };

//   return fetch(`${process.env.VUE_APP_APIURL}/promotion/delete?id=${id}`, requestOptions)
// }
