<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
      <h2>App Companion - Reset Password</h2>
 
      <!-- <v-text-field
        v-model="currentPassword"
        :type="showPass ? 'text' : 'password'"
        label="Current Password"
        @click:append="showPass = !showPass"
        :rules="passwordRules"
        >
      </v-text-field> -->

      <v-text-field
        v-model="confirmPassword"
        :type="showPass ? 'text' : 'password'"
        label="New Password"
        @click:append="showPass = !showPass"
        :rules="passwordRules"
        >
      </v-text-field>

      <v-text-field
        v-model="confirmPassword2"
        :type="showPass ? 'text' : 'password'"
        label="Confirm Password"
        @click:append="showPass = !showPass"
        :rules="passwordRules"
        >
      </v-text-field>

      <p class="error-message" v-if="resetFailed">PASSWORD RESET FAILED</p> 

      <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
        RESET
      </v-btn>

    </v-form>
  </div>
</template>

<script>

import { userService } from '@/services';
import router from "../router";

export default {
  data () {
    return {
      currentPassword: '',
      confirmPassword: '',
      confirmPassword2: '',

      resetFailed: false,

      passwordRules: [
        v => !!v || 'Password is required',
      ],
    }
  },
  methods: {
    handleSubmit (e) {
      this.$refs.form.validate();

      this.submitted = true;
      const { confirmPassword, confirmPassword2 } = this;

      if (confirmPassword != confirmPassword2) {
        alert("Passwords do not match");
        return;
      }

      var dto = {
        newPassword: confirmPassword
      }

      userService.resetPassword(dto).then((result) => {
        console.log(result);
        this.$store.dispatch('logout');
        router.push("/login")
      }, (error) => {
        console.log(error);
      });

      // const { dispatch } = this.$store;

      // if (username && password) { 
      //   dispatch('login', { username, password });
      // }

      // return false;

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
  form {
    max-width: 450px;
  }

  h2 {
    margin-bottom: 20px;
  }
</style>