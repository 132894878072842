<template>
  <div>
    <v-row justify="center">
      <v-toolbar flat class="toolbar-container" height='100'>
        <div class="toolbar-content">
          <div class="toolbar-datepicker">
            <DateRangePicker :initialDates="dates" @blur="updateDates"  />
          </div>

          <v-spacer></v-spacer>

          <v-btn class="search-btn" type="button" color="success" v-on:click="load">
            SEARCH
          </v-btn>
        </div>
      </v-toolbar>
    </v-row>
    <v-row>
      <div class="timesheet-table">
        <div class="timesheet-table-row">
          <div class="header"></div>
          <div class="header" v-for="date in dateHeaders" :key="date.idx">
            <div>{{ date.day }}</div>
            <div>{{ date.date }}</div>
          </div>
        </div>
        
        <div class="timesheet-table-row" v-for="staff in data" :key="staff.staff.id">
          <div class="staff">{{ staff.staff.name }}</div>
          <div class="header" v-for="day in staff.days" :key="day.unix">
            <div v-if="day.clockInTime != null" class="clock-timer">
              <v-icon>mdi-update</v-icon>
              <span>{{ moment(day.clockInTime).format("HH:mm") }}</span>
            </div>
            <div v-if="day.clockOutTime != null" class="clock-timer">
              <v-icon>mdi-timer</v-icon>
              <span>{{ moment(day.clockOutTime).format("HH:mm") }}</span>
            </div>
            <div v-if="day.clockInTime != null && day.clockOutTime == null" class="clock-timer">
              <v-icon>mdi-timer</v-icon>
              <span>N/A</span>
            </div>
            <div class="total-timer" v-if="day.minutes > 0">{{ day.hoursTotalFormatted }}</div>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import { reportService, productService } from '@/services';
import moment from 'moment';
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'Timesheets',
  components: {
    DateRangePicker,
  },
  data: vm => ({
    dates: [moment().isoWeekday(1).startOf('isoweek').format('YYYY-MM-DD'), moment().isoWeekday(1).startOf('isoweek').add(7, 'd').format('YYYY-MM-DD')],

    dateHeaders: [],
    isLoading: false,
    data: [],
  }),
  created () {
    if (userService.isStaff()) {
      router.push('/products');
    }
    
    this.load();    
  },
  methods: {
    updateDates(newDates) {
      this.dates = newDates;
    },
    async load() {
      var startDate = this.dates[0];
      var endDate = this.dates[1];

      this.dateHeaders = [];
      for (var m = moment(startDate); m.diff(endDate, 'days') <= -1; m.add(1, 'days')) {
        this.dateHeaders.push({
          idx: m.weekday(),
          day: m.format('dddd'),
          date: m.format("DD/MM/YYYY"),
          sortBy: m.unix()
        });
      }

      this.dateHeaders = this.dateHeaders.sort((a, b) => a.sortBy - b.sortBy);

      this.isLoading = true;
      this.data = await reportService.getTimesheets(moment(startDate).format("YYYY-MM-DD"));
      for (let d of this.data) {
        d.days = d.days.sort((a, b) => a.unix - b.unix);
      }

      this.isLoading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
 .toolbar-container {
    background: #FFF !important;
    padding: 10px;
    height: auto;
    border-radius: 8px;

    border: 1px solid lighten(#7B5CD8, 30);
  }

  .toolbar-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toolbar-datepicker {
      width: 250px;
    }
  }

  .timesheet-table {
    margin-top: 20px;
    
    width: 100%;
    overflow-y: auto;

    .timesheet-table-row {
      display: flex;
      margin-bottom: 15px;
    }

    .header {
      flex: 1;
      text-align: center;
      padding: 10px;
      background: #FFF;
      min-width: 100px;
      border-right: 1px solid #CECECE;

      div {
        &:first-child {
          border-bottom: 1px solid blue;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .staff {
    flex: 1;
    text-align: center;
    padding: 10px;
    min-width: 100px;

    background: #ededed;
    color: black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-right: 1px solid #000;
  }

  .clock-timer {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-top: 2px;
      width: 50px;
    }
  }

  .total-timer {
    margin-top: 5px;
    font-weight: bold;
  }
</style>
