<template>
  <div>
    <h2><v-icon v-on:click="goBack" color="primary" medium>mdi-arrow-left</v-icon><span>Add Category</span></h2>
    <v-form class="form" ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
      <!-- <v-text-field v-model="position" label="Position" 
        :rules="[
          v => !!v || 'Position is required', 
          v => /^\$?[\d,]+(\.\d*)?$/.test(v) || 'Invalid number'
        ]" 
      /> -->

      <v-text-field v-model="name" label="Name" :rules="nameRules" required/>

      <v-text-field v-model="description" label="Description" />

      <v-select :items="categories" item-text="name" v-model="parentCategory" item-value="id" return-object label="Parent Category"></v-select>

      <v-text-field v-model="popupNotes" label="Popup Notes" />

      <v-checkbox v-model="ageCheck" label="Age Check"></v-checkbox>

      <v-checkbox v-model="isExcludedFromDiscount" label="Is Excluded From Discount"></v-checkbox>
    
      <v-checkbox v-model="eposOnly" label="EPOS Only"></v-checkbox>

      <v-checkbox v-model="hidden" label="Hidden From UI (EPOS)"></v-checkbox>

      <v-file-input label="Image" truncate-length="15" show-size accept="image/*" prepend-icon="mdi-camera" v-model="image" @change="upload(image)"></v-file-input>

      <img v-if="imageBase64 != null" style='display:block; margin: 10px; max-width: 250px;' :src="imageBase64" />

      <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
        SUBMIT
      </v-btn> 

    </v-form>
  </div>
</template>

<script>
import { authHeader, emptyGuid } from '@/helpers';
import { categoryService } from '@/services';
import Compressor from 'compressorjs';

export default {
  data () {
    return {
      id: null,
      name: '',
      description: '',
      parentCategory: null,
      popupNotes: '',
      ageCheck: false,
      isExcludedFromDiscount: false,
      eposOnly: false,
      hidden: false,
      position: 0,

      imageBase64: null,
      image: null,

      categories: [],

      submitted: false,

      valid: false,
      nameRules: [ v => !!v || 'Name is required', ],
    }
  },
  async created () {
    await this.loadCategories();

    var id = this.$route.params.id;
    if (id != null) {
      var dbCategory = await categoryService.getById(id);
      this.id = dbCategory.id;
      this.name = dbCategory.name;
      if (dbCategory.parentCategory != null)
        this.parentCategory = this.categories.find(c => c.id == dbCategory.parentCategory.id);
      this.popupNotes = dbCategory.popupNotes;
      this.ageCheck = dbCategory.ageCheck;
      this.eposOnly = dbCategory.eposOnly;
      this.hidden = dbCategory.hidden;
      this.isExcludedFromDiscount = dbCategory.isExcludedFromDiscount;
      this.position = dbCategory.position;

      this.imageBase64 = dbCategory.imageBase64;

      // Remove this category from category list so it cannot select itself
      var idx = this.categories.indexOf(this.categories.find(c => c.id == this.id));
      this.categories.splice(idx, 1);
    }
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    loadCategories: async function() {
      this.categories = await categoryService.get();
      this.categories.unshift({
        id: null,
        name: 'None'
      });
      this.parentCategory = this.categories[0];
    },
    async handleSubmit (e) {
      if (!this.$refs.form.validate())
        return;

      this.submitted = true;

      var dto = {
        id: this.id,
        name: this.name,
        description: this.description,
        parentCategoryId: this.parentCategory.id,
        popupNotes: this.popupNotes,
        ageCheck: this.ageCheck,
        eposOnly: this.eposOnly,
        hidden: this.hidden,
        isExcludedFromDiscount: this.isExcludedFromDiscount,
        position: this.position,
        imageBase64: this.imageBase64,
      }


      var response = null;
      if (dto.id != null)
        response = await categoryService.update(dto);
      else
        response = await categoryService.add(dto);

      if (response.status != 200) {
        alert("Error saving");
        return;
      }

      this.$router.go(-1);
    },
    upload: function(file) {
      if (!file) {
        return;
      }

      new Compressor(file, {
        quality: 0.6,
        maxWidth: 512,

        success: (result) => {
          let reader = new FileReader();
          reader.onload = (event) => {
            this.imageBase64 = reader.result;
          }
          reader.readAsDataURL(result);
          
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }
</style>

