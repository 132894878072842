<template>
  <div>
    <v-row>
      <v-toolbar flat class="toolbar-container mb-2" height='100'>
        <v-col>
          <div class="toolbar-datepicker">
            <DateRangePicker :initialDates="dates" @blur="updateDates"  />
          </div>
        </v-col>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block class="search-btn" type="button" color="success" v-on:click="load">
          SEARCH
        </v-btn>
      </v-col>
      <v-col>
        <v-btn block type="button" color="primary" v-on:click="exportExcel()">
          EXPORT
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table 
        :headers="headers" :items="data" 
        :loading="isLoading"
        item-key="refundedAt" class="elevation-1 mt-5 flex-grow-1">

        <template v-slot:[`item.amount`]="{ item }">
          £{{ item.amount.toFixed(2) }}
        </template>

        <template v-slot:[`item.writeOffAt`]="{ item }">
          {{ moment(item.writeOffAt).format("DD/MM/yyyy HH:mm:ss") }}
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import { reportService, productService } from '@/services';
import { b64toBlob } from '@/helpers'
import moment from 'moment';
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'WriteOff',
  components: {
    DateRangePicker,
  },
  data: vm => ({
    dates: [moment().add(-7, 'd').toISOString().substr(0, 10), moment().toISOString().substr(0, 10)],

    isLoading: false,
    data: [],
    headers: [
      { text: 'Write Off At', align: 'start', sortable: true, filterable: true, value: 'writeOffAt' },
      { text: 'Amount', align: 'start', sortable: true, filterable: true, value: 'amount' },
      { text: 'Reason', align: 'stretch', sortable: true, filterable: true, value: 'reason' },
      { text: 'User', align: 'start', sortable: true, filterable: true, value: 'user' },
      { text: 'Product', align: 'start', sortable: true, filterable: true, value: 'product.name' },
    ],
  }),
  created () {
    if (userService.isStaff()) {
      router.push('/products');
    }
    this.load();
  },
  methods: {
    updateDates(newDates) {
      this.dates = newDates;
    },
    async load() {
      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1)
        endDate = this.dates[1];

      this.isLoading = true;
      this.data = await reportService.getWriteOffReport(startDate, endDate);
      this.isLoading = false;
    },
    exportExcel: async function() {
      this.isLoading = true;
      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1)
        endDate = this.dates[1];

      var response = await reportService.exportWriteOffReport(startDate, endDate);
      if (response == null) {
        alert("Error exporting");
        return;
      }

      const blob = b64toBlob(response, 'application/vnd.ms-excel');
      console.log(blob);
      saveAs(blob, 'WriteOff.xls');

      this.isLoading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
 .toolbar-container {
    background: #FFF !important;
    padding: 10px;
    height: auto;
    border-radius: 8px;

    border: 1px solid lighten(#7B5CD8, 30);
  }

  .toolbar-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toolbar-datepicker {
      width: 250px;
    }
  }
</style>
