<template>
  <div>
    <h2><v-icon v-on:click="goBack" color="primary" medium>mdi-arrow-left</v-icon><span>Add App</span></h2>
    <v-form class="form" ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
      <v-text-field v-model="name" label="App Name" :rules="nameRules" required/>

      <v-text-field v-model="firstname" label="Firstname" :rules="firstnameRules"/>

      <v-text-field v-model="lastname" label="Lastname" :rules="lastnameRules"/>

      <v-text-field v-model="email" label="Email" :rules="emailRules"/>

      <v-text-field v-model="addressLine1" label="Address Line 1" :rules="addressRules"/>

      <v-text-field v-model="postcode" label="Postcode" :rules="postcodeRules"/>

      <v-text-field v-model="phone" label="Phone #" :rules="phoneRules"/>

      <v-text-field v-model="password" label="Password" :rules="passwordRules"/>

      <v-text-field v-model="confirmPassword" label="Confirm Password" :rules="passwordRules"/>
    

      <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
        SUBMIT
      </v-btn> 

    </v-form>
  </div>
</template>

<script>
import { authHeader } from '@/helpers';

export default {
  data () {
    return {
      name: '',
      firstname: '',
      lastname: '',
      addressLine1: '',
      postcode: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',

      submitted: false,

      valid: false,
      
      nameRules: [ v => !!v || 'Name is required', ],
      firstnameRules: [ v => !!v || 'Firstname is required', ],
      lastnameRules: [ v => !!v || 'Lastname is required', ],

      addressRules: [ v => !!v || 'Address Line 1 is required', ],
      postcodeRules: [ v => !!v || 'Postcode is required', ],
      phoneRules: [ v => !!v || 'Phone is required', ],

      // emailRules: [ v => !!v || 'Email is required', ],
      emailRules: [
        v => !!v || 'Email is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Email must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
    }
  },
  created () {
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
     handleSubmit (e) {
      if (this.password != this.confirmPassword) {
        alert("Passwords do not match");
        return;
      }

      if (!this.$refs.form.validate())
        return;
      

      this.submitted = true;

      var dto = {
        name: this.name,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        addressLine1: this.addressLine1,
        postcode: this.postcode,
        phone: this.phone,
        password: this.password,
      }

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(dto)
      };

      fetch(`${process.env.VUE_APP_APIURL}/app/post`, requestOptions)
        // .then((response) => response.json())
        .then(response => {
          if (response.status != 200) {
            alert("Error saving.");
            return;
          }
          
          //TODO: To error check
          this.$router.go(-1);
        });
    
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }
</style>

