<script>
import { Pie } from 'vue-chartjs'
import moment from 'moment'

export default {
  extends: Pie,
  props: ['data'],
  mounted () {
    this.loadChart();
  },
  methods: {
    loadChart() {
      var topData = this.data.slice(0, 15);

      this.renderChart({
        labels: topData.map(d => d.productName),
        datasets: [{
          data: topData.map(d => d.quantity),
          backgroundColor: ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA"]
        }]
      }, 
      {
        display: true,
        responsive: true, 
        maintainAspectRatio: false, 
      })
    }
  },
  watch: {
    data: function(newVal, oldVal) {
      this.loadChart();
    }
  }
}
</script>