<template>
  <div>
    <v-toolbar flat class="toolbar-container mb-2" height='100'>
      <v-row>
        <v-col>
          <div class="toolbar-datepicker">
            <DateRangePicker :initialDates="dates" @blur="updateDates"  />
          </div>
        </v-col>

        <v-col v-if="stores.length > 1">
          <v-select :items="stores" item-text="name" v-model="selectedStoreId" item-value="id" />
        </v-col>

        <v-col>
          <v-select label="Account" :items="['All', 'Card', 'Cash']" v-model="selectedAccount"/>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row>
      <v-col>
        <v-btn block class="search-btn" type="button" color="success" v-on:click="load">
          SEARCH
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
       <v-data-table 
        :headers="headers" :items="data" 
        :loading="isLoading" :items-per-page="-1"
        :single-expand="true"
        show-expand
        
        item-key="orderRef" class="elevation-1 mt-5 flex-grow-1">

        <template v-slot:[`item.orderPlacedAt`]="{ item }">
          {{ moment(item.orderPlacedAt).format("DD/MM/yyyy HH:mm:ss") }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          £{{ item.total.toFixed(2) }}
        </template>

         <template v-slot:[`item.isDelivery`]="{ item }">
          {{ item.isDelivery == true ? 'Delivery' : 'Collection' }}
        </template>

         <template v-slot:[`item.option`]="{ item }">
          <v-btn v-if="item.account == 'Cash'" type="button" color="red" v-on:click="deleteOrder(item)">
            DELETE
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length">
            <div class="sub-total">
              <ul>
                <li v-for="product in item.items" :key="product.id">{{ product.name }} - £{{ product.cost.toFixed(2) }}</li>
              </ul>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-row>
   
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue';
import { reportService } from '@/services';
import { userService } from '@/services';
import router from "../../router";

export default {
  
  name: 'Orders',
  components: {
    DateRangePicker,
  },
  data: vm => ({
    dates: [new Date().toISOString().substr(0, 10)],
    isLoading: false,

    stores: [],
    selectedStoreId: null,
    selectedAccount: "All",

    singleExpand: false,
    data: [],
    headers: [
      // { text: 'Order Ref', align: 'start', sortable: true, filterable: true, value: 'orderRef' },
      { text: 'Order Placed At', align: 'start', sortable: true, filterable: true, value: 'orderPlacedAt' },
      { text: 'Total', align: 'start', sortable: true, filterable: true, value: 'total' },
      { text: 'Account', align: 'start', sortable: true, filterable: true, value: 'account' },
      { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
      { text: 'Collection/Delivery', align: 'start', sortable: true, filterable: true, value: 'isDelivery' },
      { text: 'Address', align: 'start', sortable: true, filterable: true, value: 'address' },
      { text: 'Option', align: 'start', sortable: false, filterable: false, value: 'option' },
    ],
  }),
  async created () {
    if (userService.isStaff()) {
      router.push('/products');
    }

    this.load();
  },
  methods: {
    updateDates(newDates) {
      this.dates = newDates;
    },
    async load() {
      var startDate = this.dates[0];
      var endDate = this.dates[0];
      if (this.dates.length > 1)
        endDate = this.dates[1];

      this.isLoading = true;
      this.data = await reportService.getOnlineOrderReport(startDate, endDate, this.selectedAccount);

      this.isLoading = false;
    },
    async deleteOrder(order) {
      var response = confirm("Are you sure you want to delete this order?");
      if (!response)
        return;

      var idx = this.data.indexOf(order);
      await reportService.deleteOnlineOrder(order.id);
      this.data.splice(idx, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar-container {
    background: #FFF !important;
    padding: 10px;
    height: auto;
    border-radius: 8px;

    border: 1px solid lighten(#7B5CD8, 30);
  }

  .toolbar-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toolbar-datepicker {
      width: 250px;
    }
  }

  .sub-total {
    ul {
      width: 100%;
      list-style-type: none;
      margin: 0;
      padding: 10px 10px;
    }
  
  }
</style>
