<template>
  <tr :class="getClass">
    <td v-for="(header, index) in headers" :key="index">
      <slot :item="item" :name="columnName(header)">
        <div :style="getAlignment(header)">
          {{ getNonSlotValue(item, header) }}
        </div>
      </slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "DataTableRowHandler",
  components: {},
  props: {
    itemClass: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    getClass() {
      return this.itemClass;
    }
  },
  methods: {
    columnName(header) {
      return `item.${header.value}`;
    },
    getAlignment(header) {
      const align = header.align ? header.align : "right";
      return `text-align: ${align}`;
    },
    getNonSlotValue(item, header) {
      var val = "";
      const headerValue = header.value;
      if (headerValue.includes(".")) {
        const headerVals = headerValue.split(".");
        if (item[headerVals[0]] != null) {
          val = item[headerVals[0]][headerVals[1]];
        }
      }
      else {
        val = item[headerValue];
      }
      
      if (val) {
        return val;
      }

      return "";
    },
  },
};
</script>