<template>
  <div>
    <h2><v-icon v-on:click="goBack" color="primary" medium>mdi-arrow-left</v-icon><span>Add Supplier</span></h2>
    <v-form class="form" ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">

      <v-text-field v-model="name" label="Name" :rules="nameRules" required/>

      <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
        SUBMIT
      </v-btn> 

    </v-form>
  </div>
</template>

<script>
import { authHeader, emptyGuid } from '@/helpers';
import { supplierService } from '@/services';

export default {
  data () {
    return {
      id: null,
      name: '',
      submitted: false,

      valid: false,
      nameRules: [ v => !!v || 'Name is required', ],
    }
  },
  async created () {
    var id = this.$route.params.id;
    if (id != null) {
      var dbSupplier = await supplierService.getById(id);
      this.id = dbSupplier.id;
      this.name = dbSupplier.name;
    }
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    async handleSubmit (e) {
      if (!this.$refs.form.validate())
        return;

      this.submitted = true;

      var dto = {
        id: this.id,
        name: this.name,
      }

      var response = null;
      if (dto.id != null)
        response = await supplierService.update(dto);
      else
        response = await supplierService.add(dto);

      if (response.status != 200) {
        alert("Error saving");
        return;
      }

      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }
</style>

