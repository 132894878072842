<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
      <h2>App Companion - Login</h2>

      <v-text-field v-model="username" label="Username" :rules="nameRules"/>

     <v-text-field
        v-model="password"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPass ? 'text' : 'password'"
        label="Password"
        @click:append="showPass = !showPass"
        :rules="passwordRules"
        >
      </v-text-field>

      <p class="error-message" v-if="loginFailed">LOGIN FAILED</p> 

      <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
        LOGIN
      </v-btn>

    </v-form>
  </div>
</template>

<script>

//import { userService } from '../services';

export default {
  data () {
    return {
      username: '',
      password: '',
      submitted: false,

      showPass: false,
      valid: false,

      nameRules: [
        v => !!v || 'Name is required',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
    }
  },
  computed: {
    loggingIn () {
      return this.$store.state.status.loggingIn;
    },
    loginFailed () {
      return this.$store.state.status.loginFailed;
    }
  },
  created () {
    this.$store.dispatch('logout');
  },
  methods: {
    handleSubmit (e) {
      this.$refs.form.validate();

      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;

      if (username && password) { 
        dispatch('login', { username, password });
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  form {
    max-width: 400px;
    margin: 0 auto;

    button {
      width: 100%;
      margin-top: 10px;
    }
  }


  .error-message {
    color: red;
  }
</style>