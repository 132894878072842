<template>
  <div>
    <v-row>
      <v-col>
        <ExtraCategories :onExtraCategorySelected="extraCategorySelected"/>
      </v-col>

      <v-col>
        <!-- <Extras /> -->
        <Extras :selectedCategoryId="selectedCategoryId"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ExtraCategories from './ExtraCategories/ExtraCategories.vue';
  import Extras from './Extras/Extras.vue';
  
  export default {
    name: 'ExtrasView',
    components: {
      ExtraCategories,
      Extras,
    },
    data: () => ({
      selectedCategoryId: null
    }),
    methods: {
      extraCategorySelected: function(extraCategory) {
        if (extraCategory == null)
          this.selectedCategoryId = null;
        else
          this.selectedCategoryId = extraCategory.id;
      }
    }
  }
</script>
